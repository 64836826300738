import ArecaDehusker from '../assets/images/categories/ARECA EQUIPMENS/ARECA DEHUSKER.jpeg';
import ArecaDehusker2 from '../assets/images/categories/ARECA EQUIPMENS/ARECA DEHUSKER2.jpeg';
import ARECATREECLIMBER from '../assets/images/categories/ARECA EQUIPMENS/ARECA TREE CLIMBER.png';
import BUNCHSEPERATOR from '../assets/images/categories/ARECA EQUIPMENS/BUNCH SEPERATOR.jpeg';
// import DHOTI from '../assets/images/categories/ARECA EQUIPMENS/DHOTI.jpg';
import EARTHAUGER from '../assets/images/categories/ARECA EQUIPMENS/EARTH AUGER.jpeg';
import GORBALPOLISHER from '../assets/images/categories/ARECA EQUIPMENS/GORBAL POLISHER.jpeg';
import HTPSPRAYER from '../assets/images/categories/ARECA EQUIPMENS/HTP SPRAYER.jpeg';
import LADDER from '../assets/images/categories/ARECA EQUIPMENS/LADDER.jpeg';
import MOTOCART125 from '../assets/images/categories/ARECA EQUIPMENS/MOTOCART 125.jpg';
import PLATEMAKINGMACHINE from '../assets/images/categories/ARECA EQUIPMENS/PLATE MAKING MACHINE.jpeg';
import REJECTIONDEHUSKER from '../assets/images/categories/ARECA EQUIPMENS/REJECTION DEHUSKER.jpeg';
import motocart350 from '../assets/images/categories/ARECA EQUIPMENS/motocart 350.png';
import woodchipper from '../assets/images/categories/ARECA EQUIPMENS/woodchipper.png';
import SolarDryer2 from '../assets/images/categories/ARECA EQUIPMENS/Solar Dryer2.jpg';
import HONDAUMK435 from '../assets/images/categories/BRUSHCUTTER/HONDA UMK-435.jpg';
import HUSQVARNABRUSHCUTTER from '../assets/images/categories/BRUSHCUTTER/HUSQVARNA BRUSHCUTTER.jpeg';
import JAGVARWEEDCUTTERJR860 from '../assets/images/categories/BRUSHCUTTER/JAGVAR WEED CUTTER – JR860.jpg';
import KKBC8640 from '../assets/images/categories/BRUSHCUTTER/KK-BC-8640.jpg';
import OLEOMACSPARTA44N from '../assets/images/categories/BRUSHCUTTER/OLEOMAC SPARTA 44 N.png';
import SUNKRAFTGI45 from '../assets/images/categories/BRUSHCUTTER/SUNKRAFT GI45.png';
import maijoBC520B from '../assets/images/categories/BRUSHCUTTER/maijo BC 520B.png';
import makitaem3400ubrushcutter from '../assets/images/categories/BRUSHCUTTER/makita-em3400u-brush-cutter-.webp';
import ULTIMA143BRUSHCUTTER from '../assets/images/categories/BRUSHCUTTER/ULTIMA 143 BRUSHCUTTER.jpg';
import KASEI from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/KASEI.png';
import MAKITAUR3501 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/MAKITA UR3501.jpg';
import SUNKRAFT from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/SUNKRAFT.png';
import cubcadet from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/cub cadet.jpeg';
import DSC_7661copy from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/BLADES/DSC_7661 copy.jpg';
import DSC_7682 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/BLADES/DSC_7682 copy.jpg';
import DSC_7716copy from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/BLADES/DSC_7716 copy.jpg';
import DSC_7679 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/ROPE/DSC_7679 copy.jpg';
import DSC_7626 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/TAP N GO/DSC_7626 copy.png';
import DSC_7644 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/TAP N GO/DSC_7644 copy.png';
import DSC_7648 from '../assets/images/categories/BRUSHCUTTER/ELECTRIC  BRUSHCUTTER/TAP N GO/DSC_7648 copy.png';
import BELT from '../assets/images/categories/BRUSHCUTTER/ACCESSORIES/ACCESSORIES/BELT.jpg';
import FACEGUARD from '../assets/images/categories/BRUSHCUTTER/ACCESSORIES/ACCESSORIES/FACEGUARD.jpeg';
import GLASS from '../assets/images/categories/BRUSHCUTTER/ACCESSORIES/ACCESSORIES/GLASS.jpeg';
import KARCHERK2350CAP from '../assets/images/categories/CAR WASHER/KARCHER K2 350 CAP.jpg';
import KISAKRAFTKKPWIM007 from '../assets/images/categories/CAR WASHER/KISAKRAFT KKPWIM007.jpg';
import MAKITHAHW102 from '../assets/images/categories/CAR WASHER/MAKITHA HW102.jpg';
import SUNKRAFTHTP from '../assets/images/categories/CAR WASHER/SUNKRAFT HTP.jpeg';
import sunkraftSK1 from '../assets/images/categories/CAR WASHER/sunkraft SK1.jpeg';
import sunkraftSK170 from '../assets/images/categories/CAR WASHER/sunkraft SK170.png';
import HONDAWB20XD from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/HONDA WB20XD.jpeg';
import HONDAWB30XD from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/HONDA WB30XD.jpeg';
import KISANKRAFT3HPkkwpp21 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/KISANKRAFT 3HP kkwpp21.jpeg';
import KISANKRAFT5HPKKWPDV178 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/KISANKRAFT 5HP KK-WPDV-178.jpg';
import SUNKRAFTSKWPP30 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/SUNKRAFT SKWPP30.jpeg';
import GX35 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/GX35.jpg';
import GX80 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/GX80-.png';
import brigGS3HP from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/brigGS 3HP.jpeg';
import gx160 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/ENGINES/gx160.png';
import ULTIMA22 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/PUMP/ULTIMA 22.jpeg';
import ULTIMA30 from '../assets/images/categories/DIESEL ENGINE AND PUMPSET/PUMP/ULTIMA 30.jpeg';
import HUSQVARNACHAINSAW from '../assets/images/categories/chainsaw/HUSQVARNA CHAINSAW.jpeg';
import himaxchainsaw from '../assets/images/categories/chainsaw/hi max chain saw.jpg';
import jagvar from '../assets/images/categories/chainsaw/jagvar.png';
import stihl from '../assets/images/categories/chainsaw/stihl.webp';
import CANFLY from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/CANFLY.jpeg';
import MINISTIHL from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/MINI STIHL.jpeg';
import OREGON from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/OREGON.jpeg';
import xlent from '../assets/images/categories/chainsaw/ELECTRIC CHAINSAW/xlent.jpeg';
import BAR from '../assets/images/categories/chainsaw/ACCESSORIES/BAR.png';
import CHAINFILINGMACHINE from '../assets/images/categories/chainsaw/ACCESSORIES/CHAIN FILING MACHINE.jpeg';
import CHAIN from '../assets/images/categories/chainsaw/ACCESSORIES/CHAIN.jpeg';
import DOUBLECLUSTERMILKINGMACHINE from '../assets/images/categories/dairy products/DOUBLE CLUSTER MILKING MACHI NE.jpg';
import MANUALMILKINGMACHINE from '../assets/images/categories/dairy products/MANUAL MILKING MACHINE.png';
import cowmat from '../assets/images/categories/dairy products/cowmat.jpg';
import curdchurner from '../assets/images/categories/dairy products/curd-churner.png';
import singleclusterMILKINGMACHINE from '../assets/images/categories/dairy products/single cluster MILKING MACHINE.jpeg';
import BHOOMI from '../assets/images/categories/dairy products/CHAFFCUTTER/BHOOMI.jpeg';
import EASYMINI from '../assets/images/categories/dairy products/CHAFFCUTTER/EASY MINI.jpeg';
import KKFMC500WITH3hpMotor from '../assets/images/categories/dairy products/CHAFFCUTTER/KK-FMC-500-WITH-3hp-Motor.jpg';
import ManualMilkingMachine from '../assets/images/categories/dairy products/CHAFFCUTTER/Manual-Milking-Machine.webp';
import KARCHERK2350CAP1 from '../assets/images/categories/dairy products/PRESSURE WASHER/KARCHER K2 350 CAP.jpg';
import KISAKRAFTKKPWIM0071 from '../assets/images/categories/dairy products/PRESSURE WASHER/KISAKRAFT KKPWIM007.jpg';
import MAKITHAHW1021 from '../assets/images/categories/dairy products/PRESSURE WASHER/MAKITHA HW102.jpg';
import sunkraftSK11 from '../assets/images/categories/dairy products/PRESSURE WASHER/sunkraft SK1.jpeg';
import sunkraftSK1701 from '../assets/images/categories/dairy products/PRESSURE WASHER/sunkraft SK170.png';
import AXE from '../assets/images/categories/garden EQUIPMENTS/AXE.jpg';
import ELECTRICHEDGETRIMMER from '../assets/images/categories/garden EQUIPMENTS/ELECTRIC HEDGE TRIMMER.webp';
import FALCON from '../assets/images/categories/garden EQUIPMENTS/FALCON .jpg';
import GARDENINGTOOLS from '../assets/images/categories/garden EQUIPMENTS/GARDENING TOOLS.jpg';
import HEDGESHEAR from '../assets/images/categories/garden EQUIPMENTS/HEDGE SHEAR.webp';
import LAWNMOWER from '../assets/images/categories/garden EQUIPMENTS/LAWNMOWER.jpeg';
import MANUALWEEDER from '../assets/images/categories/garden EQUIPMENTS/MANUAL WEEDER.jpeg';
import MANUALsprayerS from '../assets/images/categories/garden EQUIPMENTS/MANUAL sprayerS.png';
import ROOTPULLER from '../assets/images/categories/garden EQUIPMENTS/ROOT PULLER.png';
import ROSECAN from '../assets/images/categories/garden EQUIPMENTS/ROSE CAN.jpg';
import SHADENET from '../assets/images/categories/garden EQUIPMENTS/SHADENET.jpeg';
import SPRAYER from '../assets/images/categories/garden EQUIPMENTS/SPRAYER.png';
import STUBBLEMOWER from '../assets/images/categories/garden EQUIPMENTS/STUBBLE MOWER.jpg';
import Weedmat from '../assets/images/categories/garden EQUIPMENTS/Weedmat.jpeg';
import cubcadet1 from '../assets/images/categories/garden EQUIPMENTS/cub cadet.jpeg';
import easycut from '../assets/images/categories/garden EQUIPMENTS/easycut.png';
import fruitpicker from '../assets/images/categories/garden EQUIPMENTS/fruit picker.png';
import hectareproducts from '../assets/images/categories/garden EQUIPMENTS/hectare products.jpg';
import snakecatcher from '../assets/images/categories/garden EQUIPMENTS/snake-catcher.webp';
import GROWBAG from '../assets/images/categories/garden EQUIPMENTS/GROW BAG.jpg';
import HaneyEquipHoneyBeeHiveJunglewoodBox01 from '../assets/images/categories/HONEY EQUIPMENTS/Haney-Equip-Honey-Bee-Hive-Jungle-wood-Box-01 (1).jpg';
import HaneyEquipHoneyNanoBeeHiveBox01 from '../assets/images/categories/HONEY EQUIPMENTS/Haney-Equip-Honey-Nano-Bee-Hive-Box-01.jpg';
import HaneyEquipHoneyextractorsGalvanizedIronSheet from '../assets/images/categories/HONEY EQUIPMENTS/Haney-Equip-Honey-extractors-Galvanized-Iron-Sheet.jpg';
import HoneyEquipBeeSmoker01 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Bee-Smoker-01.jpg';
import HoneyEquipBeeVeil01 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Bee-Veil-01.jpg';
import HoneyEquipHoneyBeeHive02 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Honey-Bee-Hive-02.jpg';
import HoneyEquipProductsIronStand01 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Products-Iron-Stand-01.jpg';
import HoneyEquipQueenGate02 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Queen-Gate-02.jpg';
import HoneyEquipQueenGate03 from '../assets/images/categories/HONEY EQUIPMENTS/Honey-Equip-Queen-Gate-03 (1).jpg';
import cutehoneybeestickersforcaroranywherestickerbumperstickeralibees782517_grande from '../assets/images/categories/HONEY EQUIPMENTS/cute-honey-bee-stickers-for-car-or-anywhere-stickerbumper-sticker-ali-bees-782517_grande.jpg';
import MONOBLOCPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/MONOBLOC  PUMP.jpg';
import SLURRYPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/SLURRY PUMP.jpg';
import SUBMERSIBLEPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/SUBMERSIBLE PUMP.jpg';
import WATERPUMP from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/WATER PUMP.webp';
import WATERTANK from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/WATER TANK.webp';
import dripitems from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/drip items.jpg';
import finolexpipes from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/finolex pipes.jpg';
import multistagepump from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/multistage pump.jpeg';
import sprinkler from '../assets/images/categories/IRRIGATION AND ELECTRIC PUMPS/sprinkler.jpeg';
import COCONUTHUSKPEELER from '../assets/images/categories/OTHER EQUPMENTS/COCONUT HUSK PEELER.jpeg';
import GENERATOR from '../assets/images/categories/OTHER EQUPMENTS/GENERATOR.jpg';
import LADDER1 from '../assets/images/categories/OTHER EQUPMENTS/LADDER.jpg';
import MS5000MiniGasFogger from '../assets/images/categories/OTHER EQUPMENTS/MS-5000 Mini Gas Fogger.jpg';
import STUBBLEMOWER1 from '../assets/images/categories/OTHER EQUPMENTS/STUBBLE MOWER.jpg';
import Weedmat1 from '../assets/images/categories/OTHER EQUPMENTS/Weedmat.jpeg';
import blower from '../assets/images/categories/OTHER EQUPMENTS/blower.webp';
import boiler from '../assets/images/categories/OTHER EQUPMENTS/boiler.jpg';
import coconutclimber from '../assets/images/categories/OTHER EQUPMENTS/coconut climber.png';
import cylindertrolley from '../assets/images/categories/OTHER EQUPMENTS/cylinder trolley.jpg';
import easycut1 from '../assets/images/categories/OTHER EQUPMENTS/easycut.png';
import foggingmachine from '../assets/images/categories/OTHER EQUPMENTS/fogging machine.jpeg';
import karchervaccumcleaner from '../assets/images/categories/OTHER EQUPMENTS/karcher vaccum cleaner.webp';
import mistblower from '../assets/images/categories/OTHER EQUPMENTS/mist blower.png';
import raincover from '../assets/images/categories/OTHER EQUPMENTS/rain cover.jpg';
import shadenet from '../assets/images/categories/OTHER EQUPMENTS/shadenet.png';
import solartrap from '../assets/images/categories/OTHER EQUPMENTS/solar trap.png';
import CONOWEEDER from '../assets/images/categories/paddy equipments/CONOWEEDER.png';
import DRUMSEEDER from '../assets/images/categories/paddy equipments/DRUM SEEDER.jpeg';
import MANUALSEEDER from '../assets/images/categories/paddy equipments/MANUAL SEEDER.webp';
import NURSERYTRAY from '../assets/images/categories/paddy equipments/NURSERY TRAY.png';
import ROTARYTILLER from '../assets/images/categories/paddy equipments/ROTARY TILLER.jpeg';
import powertiller from '../assets/images/categories/paddy equipments/power tiller.png';
import reaper from '../assets/images/categories/paddy equipments/reaper.jpeg';
import tractor20hp60hp from '../assets/images/categories/paddy equipments/tractor 20hp-60hp.jpeg';
import transplanter from '../assets/images/categories/paddy equipments/transplanter.jpg';
import BALER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/BALER.png';
import DOZERBACKHOELOADER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/DOZER BACKHOE & LOADER.webp';
import DUCKFOOTCULTIVATOR from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/DUCKFOOT CULTIVATOR.jpg';
import MOBILESHREDDER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/MOBILE SHREDDER.png';
import MULCHER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/MULCHER.png';
import POSTHOLEDIGGER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/POST HOLE DIGGER.png';
import SPRINGCULTIVATOR from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/SPRING CULTIVATOR..png';
import SUGARCANESTUBBLESHAVER from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/SUGARCANE STUBBLE-SHAVER.png';
import rotovator from '../assets/images/categories/paddy equipments/TRACTOR ATTACHMENTS/rotovator.png';
import GREENPEPPERPEELINGMACHINE05HP from '../assets/images/categories/pepper equipmens/0.5 HP GREEN PEPPER PEELING MACHINE.png';
import CAT960PEPPERPEELINGMACHINE1HP from '../assets/images/categories/pepper equipmens/1 HP CAT 960 PEPPER PEELING MACHINE.png';
import PEPPERPEELINGMACHINE1HP from '../assets/images/categories/pepper equipmens/1HP_PEPPER-PEELING MACHINE.png';
import CAT9702HP from '../assets/images/categories/pepper equipmens/2HP CAT 970.jpg';
import PEPPERPEELINGMACHINE05hp from '../assets/images/categories/pepper equipmens/0.5 hp PEPPER PEELING MACHINE.jpg';
import BUNCHSEPERATOR1 from '../assets/images/categories/pepper equipmens/BUNCH SEPERATOR.jpg';
import ANGLEGRINDER from '../assets/images/categories/power tools/ANGLE GRINDER.jpeg';
import ELECTRICHANDHELDPRUNER from '../assets/images/categories/power tools/ELECTRIC HANDHELD PRUNER.jpg';
import HAMMERDRILL from '../assets/images/categories/power tools/HAMMER DRILL.jpg';
import IMPACTDRIVER from '../assets/images/categories/power tools/IMPACT DRIVER.jpg';
import MAKITAELECTRICBRUSHCUTTER from '../assets/images/categories/power tools/MAKITA ELECTRIC BRUSHCUTTER.jpg';
import chainfiling from '../assets/images/categories/power tools/chain filing.png';
import drillM0600B from '../assets/images/categories/power tools/drill  M0600B.jpg';
import makitaCORDLESSCHAINSAW from '../assets/images/categories/power tools/makita CORDLESS CHAINSAW.png';
import vaccumcleaner from '../assets/images/categories/power tools/vaccum, cleaner.jpeg';
import HTPSPRAYER168F from '../assets/images/categories/SPRAYERS/168F HTP SPRAYER.png';
import GATORROCKINGSPRAYER from '../assets/images/categories/SPRAYERS/GATOR-ROCKING-SPRAYER.png';
import GX80SK22 from '../assets/images/categories/SPRAYERS/GX80+SK22.webp';
import KISANKRAFTKKPS22 from '../assets/images/categories/SPRAYERS/KISANKRAFT KKPS22.jpg';
import KISANKRAFTKKPS30 from '../assets/images/categories/SPRAYERS/KISANKRAFT KKPS30.jpg';
import SUNKRAFTSK768 from '../assets/images/categories/SPRAYERS/SUNKRAFT SK768.png';
import SUNKRAFTSKPSP30 from '../assets/images/categories/SPRAYERS/SUNKRAFT SKPSP30.jpeg';
import SUNKRAFT_SK868 from '../assets/images/categories/SPRAYERS/SUNKRAFT_SK868.png';
import SUNKRAFT_SKPS18 from '../assets/images/categories/SPRAYERS/SUNKRAFT_SKPS18.png';
import SUNKRAFT_SKPSP22 from '../assets/images/categories/SPRAYERS/SUNKRAFT_SKPSP22.png';
import ULTIMA555 from '../assets/images/categories/SPRAYERS/ULTIMA 555.jpg';
import ULTIMAPOWERSPRAYER from '../assets/images/categories/SPRAYERS/ULTIMA POWER SPRAYER.jpeg';
import Ultima3HPHTPSprayerUL22 from '../assets/images/categories/SPRAYERS/Ultima 3HP HTP Sprayer UL22.jpeg';
import EcoSinglewheel from '../assets/images/categories/WHEEL BORROW/Eco Single wheel.png';
import EcoTwoWheelWLB2TR from '../assets/images/categories/WHEEL BORROW/Eco Two Wheel WLB2TR.png';
import MTNWB3Mathana3Wheelbarrow from '../assets/images/categories/WHEEL BORROW/MTNWB3 Mathana 3 Wheel barrow.png';
import PushcartThreeWheelWithBreakupPCO3WWB from '../assets/images/categories/WHEEL BORROW/Push cart Three Wheel With Breakup PCO3WWB.png';

const products = [
  {
    id: '1',
    name: 'Areca Dehusker',
    category: 'Areca-Equipments',
    subcategory: null,
    image: ArecaDehusker,
    youtubeLink: 'https://www.youtube.com/watch?v=example',
    description: ['Areca dehusking machines are used to separate the husk from the areca nut.',
      'They automate the process of peeling areca nuts, which is usually done by hand.',
      'Benefits:-',
      '•	Save time: Areca dehusking machines can save farmers time, especially during harvest season.',
      '•	Increase productivity: Automatic machines can peel areca nuts faster than manual machines. ',
      'Product details:-',
      'Capacity :	400-2400-2500kg/hour',
      'Usage/Application :	Agriculture',
      'Power :	1HP-3HP Electric Motor',
      'Weight :	200-750kg',
      'Height :	1175mm',
      'Belt	: SINGLE BELT -10BELT AVAILABLE'
    ],
  },
  {
    id: '2',
    name: 'Areca Dehusker2',
    category: 'Areca-Equipments',
    subcategory: null,
    image: ArecaDehusker2,
    youtubeLink: 'https://www.youtube.com/watch?v=example',
    description: ['Areca dehusking machines are used to separate the husk from the areca nut.',
      'They automate the process of peeling areca nuts, which is usually done by hand.',
      'Benefits:-',
      '•	Save time: Areca dehusking machines can save farmers time, especially during harvest season.',
      '•	Increase productivity: Automatic machines can peel areca nuts faster than manual machines. ',
      'Product details:-',
      'Capacity :	400-2400-2500kg/hour',
      'Usage/Application :	Agriculture',
      'Power :	1HP-3HP Electric Motor',
      'Weight :	200-750kg',
      'Height :	1175mm',
      'Belt	: SINGLE BELT -10BELT AVAILABLE'
    ],
  },
  // {
  //   id: '5',
  //   name: 'Dhoti',
  //   category: 'Areca-Equipments',
  //   subcategory: null,
  //   image: DHOTI,
  //   youtubeLink: null,
  //   description: ['• Made of durable carbon fiber. ',
  //     '• Available in various sizes.',
  //     '• Extremely lightweight. ',
  //     '• Saving time, effort and money. ',
  //     '• Suitable attachments are available for nut harvesting and spraying. '
  //   ],
  // },
  {
    id: '12',
    name: 'Rejection Dehusker',
    category: 'Areca-Equipments',
    subcategory: null,
    image: REJECTIONDEHUSKER,
    youtubeLink: null,
    description: ['Removes rejected husks to maintain quality during processing.',''],
  },
  {
    id: '4',
    name: 'Bunch Separator',
    category: 'Areca-Equipments',
    subcategory: null,
    image: BUNCHSEPERATOR,
    youtubeLink: null,
    description: ['Arecanut Separator renders this whole process automatically without manual intervention and without causing any damages to the nuts.',
      'ADVANTAGES:-',
      'Areca separation from the bunch gets faster and efficient',
      'No need of manual thrashing',
      'Easy to operate'
    ],
  },
  {
    id: '14',
    name: 'Wood Chipper',
    category: 'Areca-Equipments',
    subcategory: null,
    image: woodchipper,
    youtubeLink: null,
    description: [
      'Product details:-',
      'Shredding Capacity :	1000 kg/hr',
      'Production Capacity :	1 Ton/Hour',
      'Shredding Machine Type :	Single Shaft',
      'Power Type :	Petrol',
      'Color :	RED',
      'Automation Grade :	Automatic',
      'Speed :	3600RPM',
      'Modal : S306'
    ],
  },
  {
    id: '10',
    name: 'Motocart 125',
    category: 'Areca-Equipments',
    subcategory: null,
    image: MOTOCART125,
    youtubeLink: null,
    description: [
      'Product details:-',
      'Engine	:	1.3 HP Honda GX-35 Petrol Engine',
      'Transmission	:	Chain And Centrifugal Clutch',
      'Load Carrying Capacity	:	125 Kg',
      'Speed	:	0-5 km / hour',
      'Drive	:	Front Wheel Drive',
      'Brake	:	Drum-Front Wheels',
      'Front Wheels	:	400-8 Tractor Wheel Pattern Pneumatic Rubber Tyres',
      'Rear Wheels	:	8" x 2" Solid Polymer Wheel',
      'Tray	:	Powder Coated Metal',
      'Tray Lift	:	Manual',
      'Tray Volume	:	4.8 CFT',
      'Overall Dimension	:	1460L x 750W x 920H mm',
      'Weight	:	82 kg'
    ],
  },
  {
    id: '13',
    name: 'Motocart 350',
    category: 'Areca-Equipments',
    subcategory: null,
    image: motocart350,
    youtubeLink: null,
    description: [
      'Product details:-',
      'Engine	:	3.8 HP Honda GX-160, 4 Stroke Petrol Engine',
      'Transmission	:	CVT With Differential',
      'Load Carrying Capacity	:	350 Kg',
      'Speed	:	0-5 km / hour',
      'Drive	:	Front Wheel Drive',
      'Brake	:	Drum-Front Wheels',
      'Front Wheels	:	400-8 Tractor Wheel Pattern Pneumatic Rubber Tyres',
      'Rear Wheels	:	300 x 75 Solid Polymer Wheel',
      'Tray	: 9 CFT, Powder Coated Metal',
      'Tray Lift	:	Hydraulic Tipping System',
      'Overall Dimension	:	1675L x 760W x 1040H mm',
      'Weight	:	185 kg'
    ],
  },
  {
    id: '11',
    name: 'Plate Making Machine',
    category: 'Areca-Equipments',
    subcategory: null,
    image: PLATEMAKINGMACHINE,
    youtubeLink: null,
    description: ['Features of areca plate making machines',
      '•	Automation:  machines are fully automatic. ',
      '•	Efficiency: Machines can automate the entire process of cutting leaves, forming plates, and stacking. ',
      '•	Versatility: Machines can produce plates in different shapes and sizes. ',
      '•	Consistency: Machines can ensure that plates are uniform and meet industry standards. '
    ],
  },
  {
    id: '6',
    name: 'Earth Auger',
    category: 'Areca-Equipments',
    subcategory: null,
    image: EARTHAUGER,
    youtubeLink: null,
    description: ['Designed for easy and efficient drilling of holes for planting and fencing.'],
  },
  {
    id: '7',
    name: 'Gorbal Polisher',
    category: 'Areca-Equipments',
    subcategory: null,
    image: GORBALPOLISHER,
    youtubeLink: null,
    description: ['Areca nut has to be processed in tender green and dry condition by peeling the outer shell completely. Peeling of Areca nut is very difficult by hand. However it is being done manually by using a sharp knife with a production rate of 3kg/hr. So it is essential to use V AGRO Tech developed latest technology gear machine which will increase the production rate and safety to laborers.'],
  },
  {
    id: '3',
    name: 'Areca Tree Climber',
    category: 'Areca-Equipments',
    subcategory: null,
    image: ARECATREECLIMBER,
    youtubeLink: null,
    description: ['The machine weighs 28 kg and has two-stroke engine. Wearing safety belt, any person weighing up to 80 kg can climb the tree within 30 seconds just by the press of a button. The use of hydraulic drum with shock absorber in the engine ensures that there is no harm to life, even if the engine suddenly slips to the ground.',
      'The engine runs on petrol. For one litre petrol, one need to use 40 ml engine oil. With one litre petrol.',
      'The machine has hydraulic drum disk break. There is a handle on the model of scooter and a brake as well. Along with it, it has indicator to show petrol and oil content. By sitting on one arecanut tree, one can spray pesticides to several trees near.',
      'The machine will provide solution to problems by minimising the role of human labour in the operation of spraying pesticides and harvesting the crops.'
    ],
  },
  {
    id: '8',
    name: 'HTP Sprayer',
    category: 'Areca-Equipments',
    subcategory: null,
    image: HTPSPRAYER,
    youtubeLink: null,
    description: ['HTP power sprayers have become indispensable in modern agricultural and industrial practices due to their efficiency, versatility, and precision. Their ability to deliver high-pressure sprays makes them suitable for a wide range of applications, from crop protection and pest control to sanitation and cleaning. As technology advances, these sprayers continue to evolve, offering improved performance and greater ease of use, further solidifying their importance in various sectors.'],
  },
  {
    id: '15',
    name: 'Solar Dryer2',
    category: 'Areca-Equipments',
    subcategory: null,
    image: SolarDryer2,
    youtubeLink: null,
    description: [
      'Areca solar dryers are used to dry areca nuts, a major commercial crop in India. They can help farmers: ',
      '•	Save time: Solar dryers can dry areca nuts in hours, while traditional methods can take days ',
      '•	Save money: Solar dryers can help farmers save money on firewood ',
      '•	Improve quality: Solar dryers can produce higher quality areca nuts with better flavor and color ',
      '•	Protect from contaminants: Solar dryers can protect areca nuts from rain, dust, and insects ',
      '•	Reduce post-harvest losses: Solar dryers can help farmers reduce losses from surplus produce ',
      '•	Improve food safety: Solar dryers can create a cleaner and more hygienic drying environment '
    ],
  },
  {
    id: '9',
    name: 'Ladder',
    category: 'Areca-Equipments',
    subcategory: null,
    image: LADDER,
    youtubeLink: null,
    description: ['Lightweight and durable ladder for easy tree access during harvesting.'],
  },
  {
    id: '231',
    name: 'ULTIMA 143',
    category: 'Brushcutter',
    subcategory: null,
    image: ULTIMA143BRUSHCUTTER,
    youtubeLink: null,
    description: ['Engine Displacement : 43CC',
    'Engine Power : 2.1hp', 
    'Fuel Type : Petrol',
    'Engine : 2 Stroke', 
    'Engine Speed : 9500RPM.',
    ' Easy to Operate.', 
    ' Low Fuel Consumption with increased power.', 
    ' Walbro Carburetor used.', 
    ' Can be used for trimming weeds, small trees and other foliage, etc', 
    ' High Efficient Clutch.'],
  },
  {
    id: '18',
    name: 'Jagvar Weed Cutter JR860',
    category: 'Brushcutter',
    subcategory: null,
    image: JAGVARWEEDCUTTERJR860,
    youtubeLink: null,
    description: ['Engine Type : 2 Strokes', 
      'Type of Product : Crop Cutter', 
      'Weight : 11.68 Kg', 
      'Power : 2 HP', 
      'Displacement : 43 cc', 
      'Dimension : 87x34.5x25.5 cm'

    ],
  },
  {
    id: '21',
    name: 'Sunkraft GL45',
    category: 'Brushcutter',
    subcategory: null,
    image: SUNKRAFTGI45,
    youtubeLink: null,
    description: ['Engine Type 2 Strokes', 
      'Type of Product : Crop Cutter',
      'Weight : 11.68 Kg', 
      'Power : 2 HP', 
      'Displacement : 43 cc', 
      'Dimension : 87x34.5x25.5 cm'
    ],
  },
  {
    id: '16',
    name: 'Honda UMK 435',
    category: 'Brushcutter',
    subcategory: null,
    image: HONDAUMK435,
    youtubeLink: null,
    description: ['Air Cleaner : Dry (paper type)', 
      'Compression ratio : 8.0 : 1', 
      'Cooling System : Forced Air', 
      'Fuel Type : Unleaded gasoline', 
      'Ignition System : Transistorized Magneto Ignition', 
      'Model : Honda GX35T', 
      'Net Power : 1.2kW(1.6hp) @ 7000 rpm', 
      'Starting System : Recoil Starter', 
      'Dry Weight(Kg) : 7.5', 
      'Dimensions (L x W x H) : 1928 x 637 x 428', 
      'Engine : Honda GX35', 
      'Fuel Capacity : 0.63 L', 
      'Displacement (cm3) : 35.8'
    ],
  },
  {
    id: '22',
    name: 'Maijo BC 520B',
    category: 'Brushcutter',
    subcategory: null,
    image: maijoBC520B,
    youtubeLink: null,
    description: [' Material : Steel', 
      ' Colour : Multi-Colour', 
      ' Powered By : Gasoline-Powered', 
      ' Voltage : 250 Volts', 
      ' Dimension (L x W x H) : 189 cm x 63 cm x 47 cm'
    ],
  },
  {
    id: '20',
    name: 'Oleo Mac Sparta 44 N',
    category: 'Brushcutter',
    subcategory: null,
    image: OLEOMACSPARTA44N,
    youtubeLink: null,
    description: ['Engine Displacement : 43 CC', 
      'Engine Type : 2 Stroke', 
      'Brand : Oleo Mac', 
      'Weight : 7.4 KG', 
      'Power Source : Petrol', 
      'Material : Heavy Duty', 
      'Fuel Tank Capacity : 0.85L', 
      'Model : SPARTA 44N', 
      'Power : 2 HP'
    ],
  },
  {
    id: '19',
    name: 'KISANKRAFT KK BC 8640',
    category: 'Brushcutter',
    subcategory: null,
    image: KKBC8640,
    youtubeLink: null,
    description: ['Displacement : 42.7 cc', 
      'Speed : 6600 RPM', 
      'Weight : 8.6 kg', 
      'Engine : 2-Stroke,Air Cooled', 
      'Fuel Used : Petrol', 
      'Fuel Tank Capacity : 1.1 L', 
      'Fuel Consumption : 1 L/hr'
    ],
  },
  {
    id: '17',
    name: 'Husqvarna Brushcutter',
    category: 'Brushcutter',
    subcategory: null,
    image: HUSQVARNABRUSHCUTTER,
    youtubeLink: null,
    description: ['Cylinder displacement : 32.6 cm³', 
      'Output shaft power1 : 0.9 kW', 
      'Fuel tank volume : 0.9 l', 
      'Fuel consumption : 550 g/kWh', 
      'Maximum power speed : 7,000 rpm'
    ],
  },
  {
    id: '23',
    name: 'Makita EM3400U',
    category: 'Brushcutter',
    subcategory: null,
    image: makitaem3400ubrushcutter,
    youtubeLink: null,
    description: ['Engine Displacement : 43CC',
    'Engine Power : 2.1hp', 
    'Fuel Type : Petrol',
    'Engine : 2 Stroke', 
    'Engine Speed : 9500RPM.',
    ' Easy to Operate.', 
    ' Low Fuel Consumption with increased power.', 
    ' Walbro Carburetor used.', 
    ' Can be used for trimming weeds, small trees and other foliage, etc', 
    ' High Efficient Clutch.'],
  },
  {
    id: '25',
    name: 'Makita UR3501',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: MAKITAUR3501,
    youtubeLink: null,
    description: ['Brand : Makita', 
      'Power Source : Corded Electric', 
      'Colour : Multi-Colored', 
      'Item Weight : 4.3 Kilograms', 
      'Cutting Width : 350 Millimeters', 
      'Product Dimensions : 20L x 18.5W Centimeters', 
      'Speed : 7500 RPM'],
  },
  {
    id: '27',
    name: 'Cub Cadet',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: cubcadet,
    youtubeLink: null,
    description: ['Brand : Cub Cadet', 
      'Power (HP) : 4.3', 
      'Power Type : Petrol/Diesel', 
      'Size/Dimension : 22 inch'],
  },
  {
    id: '24',
    name: 'Kasei',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: KASEI,
    youtubeLink: null,
    description: ['Lightweight and efficient electric brush cutter suitable for precise trimming.'],
  },
  {
    id: '26',
    name: 'Sunkraft Trolley',
    category: 'Brushcutter',
    subcategory: 'Electric-Brushcutter',
    image: SUNKRAFT,
    youtubeLink: null,
    description: ['Reliable electric brush cutter for medium-duty trimming and clearing tasks.'],
  },
  {
    id: '32',
    name: 'DSC 7626 Tap N Go',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7626,
    youtubeLink: null,
    description: [' Heavy Duty', 
      ' Easy to Use', 
      ' Used for cutting grass and weeds. To be used in a stone region.', 
      ' This tap and go model is compatible with brush cutters.'
    ],
  },
  {
    id: '33',
    name: 'DSC 7644 Tap N Go',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7644,
    youtubeLink: null,
    description: [' Heavy Duty', 
      ' Easy to Use  Used for cutting grass and weeds. To be used in a stone region.', 
      ' This tap and go model is compatible with brush cutters.'],
  },
  {
    id: '34',
    name: 'DSC 7648 Tap N Go',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7648,
    youtubeLink: null,
    description: [' Heavy Duty', 
      ' Easy to Use  Used for cutting grass and weeds. To be used in a stone region.', 
      ' This tap and go model is compatible with brush cutters.'],
  },
  {
    id: '28',
    name: 'DSC 7661 Blade',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7661copy,
    youtubeLink: null,
    description: ['Blade is a attachment of Grass / Brush Cutter used to trim Grass & Weeds.', 
      'Material of blade is heavy duty CARBON STEEL. These blades used for grass cutting, weed cutting and crop cutting and it can be fix in any type of brush cutter.'],
  },
  {
    id: '29',
    name: 'DSC 7682 Blade',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7682,
    youtubeLink: null,
    description: ['Blade is a attachment of Grass / Brush Cutter used to trim Grass & Weeds.', 
      'Material of blade is heavy duty CARBON STEEL. These blades used for grass cutting, weed cutting and crop cutting and it can be fix in any type of brush cutter.'],
  },
  {
    id: '30',
    name: 'DSC 7716 Blade',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7716copy,
    youtubeLink: null,
    description: ['Blade is a attachment of Grass / Brush Cutter used to trim Grass & Weeds.', 
      'Material of blade is heavy duty CARBON STEEL. These blades used for grass cutting, weed cutting and crop cutting and it can be fix in any type of brush cutter.'],
  },
  {
    id: '31',
    name: 'DSC 7679 Rope',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: DSC_7679,
    youtubeLink: null,
    description: [' Product Type : Nylon Rope', 
      ' Rope Type : round and square', 
      ' Line Diameter : 3 mm', 
      ' Length : 1mtr -50 Meter availbale', 
      ' Use to cut through grass, weeds and thick brush cleanly and quickly.', 
      ' Unbreakable, Long Lasting, Weatherproof.'],
  },
  {
    id: '35',
    name: 'Belt',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: BELT,
    youtubeLink: null,
    description: ['Comfortable belt for ease of carrying and using brush cutters.'],
  },
  {
    id: '36',
    name: 'Face Guard',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: FACEGUARD,
    youtubeLink: null,
    description: ['Protective face guard for safe operation during trimming and cutting.'],
  },
  {
    id: '37',
    name: 'Glass',
    category: 'Brushcutter',
    subcategory: 'Brushcutter-Accessories',
    image: GLASS,
    youtubeLink: null,
    description: ['Safety glass for protection while operating brush cutters.'],
  },
  {
    id: '40',
    name: 'Makita HW102',
    category: 'Car-Washer',
    subcategory: null,
    image: MAKITHAHW102,
    youtubeLink: null,
    description: ['•	Makita HW102 Pressure Washer comes with a telescopic handle for added maneuverability.',
      '•	Max Pressure:100 bar. Max Flow:360l/h',
      '•	Thermoplastic type hose',
      "•	Quick connection for accessories and hose, Makita's Total-Stop-System (TSS)",
      '•	Input power:1300 Watts',
      'Brand :	Makita',
      'Power Source	: Corded Electric',
      'Colour :	BLUE',
      'Maximum Pressure :	100 Bars',
      'Item Weight	: 6 Kilograms',
      'Hose Length :	5 Metres'
    ]
  },
  {
    id: '42',
    name: 'Sunkraft SK1',
    category: 'Car-Washer',
    subcategory: null,
    image: sunkraftSK1,
    youtubeLink: null,
    description: ['Brand	:	SUNKRAFT',
      'Model Number	:	SK1',
      'Shade : BLUE',
      'Input Power : 1800w',
      'Voltage : 220v/50hz',
      'Type	:	Pressure Washer',
      'Maximum Pressure	:	130 Bar',
      'Water Flow Rate	:	390 L/h',
      'Color	:	Blue, Black'
    ]
  },
  {
    id: '39',
    name: 'Kisankraft KKPWIM007',
    category: 'Car-Washer',
    subcategory: null,
    image: KISAKRAFTKKPWIM007,
    youtubeLink: null,
    description: ['•	Power rated 2500W, input power 230v 50Hz',
      '•	Motor type induction motor',
      '•	Maximum Pressure: 19.5MPa',
      '•	Maximum pressure 19.5MPa',
      '•	Cleaning pressure 13MPa',
      '•	Weight of the machine 22 kg',
      '•	Output capacity 6.5L/min'
    ]
  },
  {
    id: '41',
    name: 'Sunkraft HTP',
    category: 'Car-Washer',
    subcategory: null,
    image: SUNKRAFTHTP,
    youtubeLink: null,
    description: ['Engine Power : 4.2 Kw / 6.5 HP',
      'Engine RPM : 3600',
      'Discharge : 20-30 Lpm',
      'Pressure : 20-45 Kg / Cm2',
      'Suction & Delivery Hose : 3 Mtr'
    ]
  },
  {
    id: '38',
    name: 'Karcher K2 350 CAP',
    category: 'Car-Washer',
    subcategory: null,
    image: KARCHERK2350CAP,
    youtubeLink: null,
    description: ['•	High-Performance machine with High-Quality N-Cor pump generates Max Pressure of 110 BAR using only 1400 Watt Power and Max Flow Rate of 360 L/Hr',
      '•	Includes a High pressure lance and Dirt blaster – Ideal for cleaning delicate surfaces such as car, or harder surfaces like stone and brickwork.',
      '•	Integrated Detergent Tank – Built in detergent tank allows you to easily apply detergent directly through the machine',
      '•	Mobile and compact: the all-rounder. Perfect for light soiling ',
      'Brand :	Kärcher',
      'Power Source :	Corded Electric',
      'Colour :	YELLOW',
      'Maximum Pressure :	110 Bars',
      'Item Weight :	7.4 Kilograms',
      'Hose Length :	6 Metres'
    ]
  },
  {
    id: '43',
    name: 'Sunkraft SK170',
    category: 'Car-Washer',
    subcategory: null,
    image: sunkraftSK170,
    youtubeLink: null,
    description: ['Pressure	: 130 Bar',
      'Power :	2200 Watt',
      'Flow Rate :	12 L/min',
      'Motor Rating :	3 HP',
      'Application Area	: Workshop Cleaning, Floor Cleaning, Tank Cleaning, Car & Truck Cleaning',
      'Power Source :	Electric Motor',
      'Motor Phase :	Single Phase',
      'Voltage :	220V',
      'Hose Length :	10 Metre'
    ]
  },
  {
    id: '48',
    name: 'Sunkraft SKWPP30',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: SUNKRAFTSKWPP30,
    youtubeLink: null,
    description: ['Engine Type : 4 Stroke Petrol Engine',
      'Engine Power : 4.5 HP (168F Engine)',
      'Pump Suction : 3" (80mm)',
      'Pump Discharge : 3" (80mm)'
    ]
  },
  {
    id: '44',
    name: 'Honda WB20XD',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: HONDAWB20XD,
    youtubeLink: null,
    description: ['Air Cleaner :	Dual Type',
      'Bore X Stroke (mm) :	68.0 X 45.0',
      'Displacement (cm3) :	163',
      'Description Code :	4-Stroke, Single Cylinder, Air cooled, Horizontal Shaft, Over Head Valve',
      'Fuel Tank Capacity (L) :	3.1',
      'Fuel Type :	Petrol',
      'Net Power :	3.6 kw / 3600 rpm'
    ]
  },
  {
    id: '45',
    name: 'Honda WB30XD',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: HONDAWB30XD,
    youtubeLink: null,
    description: ['Air Cleaner :	Dual Type',
      'Bore X Stroke (mm) :	68.0 X 45.0',
      'Displacement (cm3) :	163',
      'Description Code :	4-Stroke, Single Cylinder, Air cooled, Horizontal Shaft, Over Head Valve',
      'Fuel Tank Capacity (L) :	3.1',
      'Fuel Type :	Petrol',
      'Net Power :	3.6 kw / 3600 rpm'
    ]
  },
  {
    id: '46',
    name: 'Kisankraft 3HP KKWP-P21',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: KISANKRAFT3HPkkwpp21,
    youtubeLink: null,
    description: ['Rated Power : 3 kW (4 hp)',
      'Displacement : 196 cc',
      'Speed : 3600 RPM',
      'Engine : 4-Stroke, Air Cooled',
      'Fuel Used : Petrol',
      'Duty Head : 24 m',
      'Duty Discharge : 8.5 L/s',
      'Suction Size : 80 mm',
      'Delivery Size : 80 mm',
      'Fuel Tank Capacity : 3.6 L',
      'Fuel Consumption : 1350 g/h'
    ]
  },
  {
    id: '47',
    name: 'Kisankraft 5HP KK-WPDV-178',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: null,
    image: KISANKRAFT5HPKKWPDV178,
    youtubeLink: null,
    description: ['Rated Power : 4 kW',
      'Displacement : 296 cc',
      'Speed : 3600 RPM',
      'Engine : 4-Stroke, Single Cylinder',
      'Fuel Used : Diesel',
      'Duty Head : 7 m',
      'Duty Discharge : 8.5 L/s',
      'Inlet Size : 80 mm (3.1″)',
      'Outlet Size : 80 mm (3.1″)',
      'Fuel Tank Capacity : 3.5 L',
      'Fuel Consumption : 308 g/h'
    ]
  },
  
  {
    id: '49',
    name: 'Honda GX35',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: GX35,
    youtubeLink: null,
    description: ['Bore X Stroke (mm) :	39 x 30 mm',
      'Compression ratio :	8.0 : 1',
      'Description Code :	4-stroke single cylinder OHC petrol engine',
      'Fuel Tank Capacity (L) :	0.63 Litre',
      'Ignition System	: Transistorised',
      'Net Power :	1.0 kW ( 1.3 HP ) / 7000 rpm',
      'Oil Capacity	: 0.1 Litre',
      'Starting System	: Recoil',
      'Dry Weight(Kg) :	3.33 kg',
      'Displacement :	35.8 cm³',
      'Dimensions (L x W x H) :	198 x 234 x 240 mm',
      'Fuel cons. at cont. rated power :	0.71 L/h - 7000 rpm',
      'Max. net torque :	1.6 Nm / 0.16 kgfm / 5500 rpm',
      'Lubrication :	Oil mist'
    ]
  },
  {
    id: '50',
    name: 'Honda GX80',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: GX80,
    youtubeLink: null,
    description: ['Bore X Stroke (mm) :	46 x 48 mm',
      'Compression ratio :	9:1 ',
      'Description Code :	4-stroke single cylinder, OHV petrol engine, 25° inclined cylinder, Horizontal shaft',
      'Fuel Tank Capacity (L) :	1.5 Litre',
      'Ignition System	: Transistor Type Magneto Ignition',
      'Net Power :	1.5 kW ( 2 HP ) / 3600 rpm',
      'Oil Capacity	: 0.36 Litre',
      'Starting System	: Recoil (el. start optional)',
      'Dry Weight(Kg) :	11.1 kg',
      'Displacement :	79.7 cm³',
      'Cylinder sleeve type :	Cast iron sleeve',
      'Dimensions (L x W x H) :	275 x 342 x 323 mm',
      'Fuel cons. at cont. rated power :	0.6 L/h - 3600 rpm',
      'Cont rated power :	1.2 kW ( 1.6 HP ) / 3600 rpm',
      'Max. net torque :	4.5 N.m / (0.46 kgf.m / 2500 rpm'
    ]
  },
  {
    id: '52',
    name: 'Honda GX160',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: gx160,
    youtubeLink: null,
    description: ['Bore X Stroke (mm) :	68 x 45 mm',
      'Compression ratio :	9.0 : 1 ',
      'Description Code :	4-stroke single cylinder, OHV petrol engine, horizontal shaft',
      'Fuel Tank Capacity (L) :	3.1 Litre',
      'Ignition System	: Transistorised type magneto ingnition system',
      'Net Power :	3.6 kW ( 4.8 HP ) / 3600 rpm',
      'Oil Capacity	: 0.58 Litre',
      'Starting System	: Recoil',
      'Dry Weight(Kg) :	15.1 kg',
      'Displacement :	163 cm³',
      'Cylinder sleeve type :	Cast iron sleeve',
      'Dimensions (L x W x H) :	312 x 362 x 346 mm',
      'Fuel cons. at cont. rated power :	1.4 L/h - 3600 rpm',
      'Cont rated power : 	2.9 kW ( 3.9 HP ) / 3600 rpm',
      'Max. net torque :	4.5 N.m / (0.46 kgf.m / 2500 rpm'
    ]
  },
  {
    id: '51',
    name: 'Briggs & Stratton 3HP',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'ENGINES',
    image: brigGS3HP,
    youtubeLink: null,
    description: ['Engine Type : Single Cylinder, 4 Stroke , Air Cooled',
      'Gross Power : 2.1 Kw/2.8HP ',
      '@3600 RPM as per IS : 7347 Certification',
      'Displacement : 127 CC',
      'Cylinder : Cast Iron Sleeve',
      'Bore & Stroke 62,0 x 42,0 MM',
      'Fuel Tank Capacity : 2 L',
      'Oil Capacity : 0.6 L',
      'Features : Lo-Tone Muffler, Dual Ball Bearing, Forged Iron Crankshat'
    ]
  },
  {
    id: '53',
    name: 'ULTIMA 22',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'PUMP',
    image: ULTIMA22,
    youtubeLink: null,
    description: ['RPM : 800-1000',
      'Suction : 16.5 - 20.5 L/Pm',
      'Pump Pressure : 20-45 Kg / Cm2',
      'Power : 1.5 - 3HP',
      'Suction & Delivery Hose : 5 Mtr'
    ]
  },
  {
    id: '54',
    name: 'ULTIMA 30',
    category: 'DIESEL-ENGINE-PUMPSET',
    subcategory: 'PUMP',
    image: ULTIMA30,
    youtubeLink: null,
    description: ['RPM : 1200-1400',
      'Suction : 29 - 45 L/Pm',
      'Pump Pressure : 20-45 Kg / Cm2',
      'Power : 2 - 5HP',
      'Suction & Delivery Hose : 5 Mtr'
    ]
  },
  {
    id: '57',
    name: 'Jagvar Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: jagvar,
    youtubeLink: null,
    description: ['This Chainsaw is a high-performance power tool designed for all your heavy-duty cutting tasks.', 
      'The chainsaw features a robust 52cc engine delivering powerful and efficient performance.', 
      'Its 22-inch bar length is ideal for cutting larger logs and trees, offering ample reach and control.', 
      'This fuel-powered chainsaw has been designed with safety and convenience in mind.', 
      'reducing wear and tear and prolonging the lifespan of the unit.', 
      'The handle reduces operator fatigue and provides a comfortable grip during extended use.']
  },
  {
    id: '56',
    name: 'Himax Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: himaxchainsaw,
    youtubeLink: null,
    description: ['Guide Bar Length	:	55.8 cm', 
      'Blade Material	:	Cast Iron', 
      'Engine Type	:	Gasoline/Petrol', 
      'Displacement	:	58 cc', 
      'Motor Speed	:	3300 RPM'
    ]
  },
  {
    id: '58',
    name: 'Stihl Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: stihl,
    youtubeLink: null,
    description: ['This chainsaw gets back to basics with improved engine air flow and shroud design.', 
      'With its improved design comes enhanced engine cooling and air filtration, giving you more consistent performance in the field.', 
      'Make quick work of trimming branches, cutting small trees and clearing fallen limbs after a storm.', 
      'If you’re looking for a reliable, light-duty chainsaw for occasional use.'
    ]
  },
  {
    id: '55',
    name: 'Husqvarna Chainsaw',
    category: 'Chainsaw',
    subcategory: null,
    image: HUSQVARNACHAINSAW,
    youtubeLink: null,
    description: ['Robust, reliable and easy to use saw with ample cutting performance for homeowners.', 
      'The perfect garden chainsaw for occasional cutting tasks such as cutting firewood and felling smaller trees.', 
      'Starts quickly and handles easily, and thanks to the side mounted chain tensioner, tensioning the chain is both quick and easy.'
    ]
  },
  {
    id: '61',
    name: 'Oregon Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: OREGON,
    youtubeLink: null,
    description: ['•	POWERFUL PERFORMANCE - 15 Amp electric chainsaw with built-in chain brake. Ideal saw for professionals and homeowners who cut wood in harsh environments. Low vibration, low recoil saw.', 
    '•	ControlCut Saw Chain - This Oregon chainsaw comes with an included low-maintenance chain, which produces a smooth cut every time and stays sharper longer.', 
    '•	SMART DESIGN - 16 inch chainsaw with built-in chain tightening system no tools required. Other features include oil level window and front hand guard to protect from flying debris. Quiet between cuts.', 
    '•	AUTOMATIC GREASER - Automatic oiler distributes oil on the bar and chain while on the saw, keeping your chainsaw lubricated and preventing premature wear.', 
    '•	ERGONOMIC - Lightweight and balanced, low vibration, over-molded comfort handle. 2-year warranty included. Includes 1 electric chainsaw, 16" guide bar and chain.'
    ]
  },
  {
    id: '62',
    name: 'Xlent Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: xlent,
    youtubeLink: null,
    description: ['•	VOLTAGE : 220V', 
      '•	INPUT POWER  : 2400 W', 
      '•	NO LOAD SPEED  : 4800 RPM', 
      '•	MAX CUTTING LENGTH : 405MM', 
      'Weight	8.5 kg', 
      'Dimensions	56 × 50 × 33 cm'
    ]
  },
  {
    id: '59',
    name: 'CanFly Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: CANFLY,
    youtubeLink: null,
    description: ['Power Type	: Electricity', 
      'Guide bar size :	16 inch(405mm)', 
      'Chain	: 16" chain', 
      'Brand Name :	CANFLY professional chainsaw', 
      'Feature :	Anti-Slip', 
      'Rate voiltage :	220V', 
      'output power	: 1.7kw', 
      'saw chain speed :	480m/min'
    ]
  },
  {
    id: '60',
    name: 'Mini Stihl Electric Chainsaw',
    category: 'Chainsaw',
    subcategory: 'ELECTRIC-CHAINSAW',
    image: MINISTIHL,
    youtubeLink: null,
    description: ['Type of Product :	Cordless Saw', 
      'Model No :	GTA 26', 
      'Weight	: 1.45 Kg', 
      'Battery Type :	Li-Ion / AS', 
      'Voltage	10.8V']
  },
  {
    id: '63',
    name: 'Chainsaw Bar',
    category: 'Chainsaw',
    subcategory: 'CHAINSAW-ACCESSORIES',
    image: BAR,
    youtubeLink: null,
    description: ['ULTBAR18	Ultima Guide Bar 18"', 
      'ULTBAR180	Ultima Guide Bar 18" MS180', 
      'ULTBAR250	Ultima Guide Bar 18" MS250', 
      'ULTBAR170	Ultima Guide Bar 16" MS170']
  },
  {
    id: '65',
    name: 'Chainsaw Chain',
    category: 'Chainsaw',
    subcategory: 'CHAINSAW-ACCESSORIES',
    image: CHAIN,
    youtubeLink: null,
    description: ['ULT21LPX	Ultima Chain 18"', 
      'ULTSTRCT	Ultima Straight Cut Chain 18"', 
      'ULTCN180	Ultima Chain 18" MS180', 
      'ULTSCC180	Ultima Straigh Cut Chain 18" MS180', 
      'ULTCN250	Ultima Chain 18" MS250'
    ]
  },
  {
    id: '64',
    name: 'Chain Filing Machine',
    category: 'Chainsaw',
    subcategory: 'CHAINSAW-ACCESSORIES',
    image: CHAINFILINGMACHINE,
    youtubeLink: null,
    description: ['Chain Filing Machine, designed to keep your chainsaw chains sharp and in optimal cutting condition with minimal effort.']
  },
  {
    id: '70',
    name: 'Single Cluster Milking Machine',
    category: 'Dairy-Products',
    subcategory: null,
    image: singleclusterMILKINGMACHINE,
    youtubeLink: null,
    description: [
      '• The weight of the machine is 35 kg.',
      '• Its power requirement is 180W (220 V Single Phase) and it can milk one animal at a time.',
      '• This Milking machine is driven by an AC geared motor, through a linkage.',
      '• The Machine is mounted on a stainless steel trolley having polymer wheels.',
      '• The trolley can be dragged easily in the cow shed.',
      '• The machine can be operated manually by handle in case of emergency.',
      '• It includes two reciprocating vacuum pumps linked to a 180 watt single-phase electrical geared motor.',
      '• Milking machines are made of food-grade 304 stainless steel sheets.',
  ]
  
  },
  {
    id: '66',
    name: 'Double Cluster Milking Machine',
    category: 'Dairy-Products',
    subcategory: null,
    image: DOUBLECLUSTERMILKINGMACHINE,
    youtubeLink: null,
    description: [
      '• The weight of the machine is 50 kg.',
      '• Its power requirement is 180W (220 V Single Phase).',
      '• It can milk two animals at a time.',
      '• It consists of two control blocks with vacuum gauge, two 20 liters milking cans made of food-grade 304 stainless steel sheets, and two cluster assemblies with four teat adapters with silicon rubber teat liners.'
  ]
  
  },
  {
    id: '67',
    name: 'Manual Milking Machine',
    category: 'Dairy-Products',
    subcategory: null,
    image: MANUALMILKINGMACHINE,
    youtubeLink: null,
    description: [
      '• The weight of the machine is 18 kg.',
      '• It does not require any external power source and it can milk one animal at a time.',
      '• It is a basic milking machine, mounted on a stainless steel frame.',
      '• It has two reciprocating vacuum pumps linked to a handle for manual back and forth operation.',
      '• Milking machines are made of food-grade 304 stainless steel sheets.'
  ]
  
  },
  {
    id: '68',
    name: 'Cow Mat',
    category: 'Dairy-Products',
    subcategory: null,
    image: cowmat,
    youtubeLink: null,
    description: [
      '• The primary purpose of cow mats is to improve the welfare and productivity of dairy cows by providing a comfortable surface to rest on, reducing the risk of injuries such as hoof problems and sores, and increasing overall cow comfort. The mats also help to insulate against cold and damp floors, providing a warmer environment for the cows during colder seasons.',
      '• A cow mat, also known as a cattle mat or cow mattress, is a specialized type of flooring designed to provide comfort and support for cows in barns or milking parlors. These mats are typically made of durable materials like rubber and are placed on the floor of the barn or milking area where cows spend a significant amount of time standing or lying down.',
      '• The cows enjoy sitting on the Cow Mats thereby increasing their resting time. With a special blend of rubber and EVA, you can rely on a long-lasting quality of the cow & buffalo mats.',
      'Specification :',
      '• Product Type : Cow Mat',
      '• Material : Rubber',
      '• Size : 4 feet x 6.5 feet With Lock',
      '• Thickness : 17 mm',
      '• Weight : 30 kgs (Approx.)'
  ]
  
  },
  {
    id: '69',
    name: 'Curd Churner',
    category: 'Dairy-Products',
    subcategory: null,
    image: curdchurner,
    youtubeLink: null,
    description: [
      'ADVANTAGES',
      '• Alleviates manual curd churning',
      '• No manual intervention required',
      '• Clean and healthy',
      '• Easy to clean and maintain',
      '• The churner is powered by 120 Watts motor',
      '• The above variants has a capacity of 2 to 12 ltr',
      '• High quality product with excellent after sale support',
      'USES',
      '• Domestic use for butter extraction',
      '• Small to medium sized dairy industries',
      'SPECIALTIES',
      '• Manufactured using good quality stainless steel and other materials',
      '• Compact and good looking',
      '• Portable and smooth rotation, no spillage',
      '• Efficient power usage',
      '• Less space occupation',
      'Material : Stainless Steel Rod',
      'Dimension (LxWxH Inches) : 7x8x15',
      'Capacity : 0.5 to 4 Ltr Churning',
      'Motor : 45 W',
      'Comment : Single Directional Churning',
      'Appearance : Powder Coated'
  ]
  
  },
  {
    id: '71',
    name: 'Bhoomi Chaffcutter',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: BHOOMI,
    youtubeLink: null,
    description: [
      'Specification :',
      '• Gear : Cast Iron',
      '• Wheel : Cast Iron & 810mm',
      '• Capacity : 350 Kg',
      '• Weight : 120 Kg',
      '• Cutting Blade : High Carbon Steel',
      '• Electric Motor : 1440 Rpm (Single Phase)',
      '• 2HP Motor'
  ]
  
  },
  {
    id: '72',
    name: 'Easy Mini Chaffcutter',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: EASYMINI,
    youtubeLink: null,
    description: [
      'Specification :',
      '• Product Type : Chaff Cutter',
      '• Motor Power : 3HP Single phase',
      '• Speed of Motor : 2800 R/MIN',
      '• Shaft Diameter : 24MM',
      '• Pulley Diameter : 68MM',
      '• Capacity : 400-1000 Kg/ Hr',
      '• No. of Blade : 4 Pcs Manganese Steel',
      '• Final Size After Cutting : 6MM-35MM',
      '• Conveyor Belt : 2MM Long Belt L 1200MM',
      '• Weight : 63.8 kgs (With Motor)',
      'Description :',
      'Introducing the Heavy Duty Chaff Cutter, CC-NB-0.4 With a robust 3 HP motor, it boasts efficient, enabling a remarkable processing capacity of 400-1000 kg per hour. This cutter maintains a rapid speed of 2800 RPM for optimal performance.'
  ]
  
  },
  {
    id: '73',
    name: 'KK FMC 500 with 3hp Motor',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: KKFMC500WITH3hpMotor,
    description: [
      'Chaff cutter is a mechanical tool which is used to cut the hay into small pieces. These are then used to feed the cattle. Chaff cutter KK-FMC-500 is a mechanical tool which comes with a heavy-duty hardened blade available in both electric motor and diesel engine variants. It is manufactured using high-quality materials that are durable and built to withstand enormous load and have a high-performance life.',
      '• This machine can cut all types of fodders into uniform pieces to feed the cattle.',
      '• KisanKraft chaff cutter comes with least maintenance.',
      '• High-quality materials are used to withstand enormous load.',
      '• Refined engine for smooth operation.',
      '• Easy to handle and saves labour cost.',
      '• Speed : 2500 RPM.',
      '• Maximum Output-Dry : 700.'
  ]
  
  },
  {
    id: '74',
    name: 'Manual Milking Machine',
    category: 'Dairy-Products',
    subcategory: 'CHAFFCUTTER',
    image: ManualMilkingMachine,
    youtubeLink: null,
    description: [
      '• The weight of the machine is 18 kg.',
      '• It does not require any external power source and it can milk one animal at a time.',
      '• It is a basic milking machine, mounted on a stainless steel frame.',
      '• It has two reciprocating vacuum pumps linked to a handle for manual back and forth operation.',
      '• Milking machines are made of food-grade 304 stainless steel sheets.'
  ]
  },
  {
    id: '77',
    name: 'Makita HW102',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: MAKITHAHW1021,
    youtubeLink: null,
    description: [
      '• Makita HW102 Pressure Washer comes with a telescopic handle for added maneuverability.',
      '• Max Pressure: 100 bar. Max Flow: 360l/h',
      '• Thermoplastic type hose',
      '• Quick connection for accessories and hose, Makita\'s Total-Stop-System (TSS)',
      'Input power: 1300 Watts',
      'Brand : Makita',
      'Power Source : Corded Electric',
      'Colour : BLUE',
      'Maximum Pressure : 100 Bars',
      'Item Weight : 6 Kilograms',
      'Hose Length : 5 Metres',
  ]
  
  },
  {
    id: '78',
    name: 'Sunkraft SK1',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: sunkraftSK11,
    youtubeLink: null,
    description: [
      'Brand : SUNKRAFT',
      'Model Number : SK1',
      'Shade : Blue',
      'Input Power : 1800w',
      'Voltage : 220v/50hz',
      'Type : Pressure Washer',
      'Maximum Pressure : 130 Bar',
      'Water Flow Rate : 390 L/h',
      'Color : Blue, Black',
  ]
  
  },
  {
    id: '76',
    name: 'Kisankraft KKPWIM007',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: KISAKRAFTKKPWIM0071,
    youtubeLink: null,
    description: [
      '• Power rated 2500W, input power 230v 50Hz',
      '• Motor type induction motor',
      '• Maximum Pressure: 19.5MPa',
      '• Maximum pressure 19.5MPa',
      '• Cleaning pressure 13MPa',
      '• Weight of the machine 22 kg',
      '• Output capacity 6.5L/min',
  ]
  
  },
  {
    id: '75',
    name: 'Karcher K2 350 CAP',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: KARCHERK2350CAP1,
    youtubeLink: null,
    description: [
      '• High-Performance machine with High-Quality N-Cor pump generates Max Pressure of 110 BAR using only 1400 Watt Power and Max Flow Rate of 360 L/Hr',
      '• Includes a High pressure lance and Dirt blaster – Ideal for cleaning delicate surfaces such as car, or harder surfaces like stone and brickwork.',
      '• Integrated Detergent Tank – Built in detergent tank allows you to easily apply detergent directly through the machine',
      '• Mobile and compact: the all-rounder. Perfect for light soiling',
      'Brand : Kärcher',
      'Power Source : Corded Electric',
      'Colour : YELLOW',
      'Maximum Pressure : 110 Bars',
      'Item Weight : 7.4 Kilograms',
      'Hose Length : 6 Metres',
  ]
  
  },
  {
    id: '79',
    name: 'Sunkraft SK170',
    category: 'Dairy-Products',
    subcategory: 'PRESSURE-WASHER',
    image: sunkraftSK1701,
    youtubeLink: null,
    description: [
      'Pressure : 130 Bar',
      'Power : 2200 Watt',
      'Flow Rate : 12 L/min',
      'Motor Rating : 3 HP',
      'Application Area : Workshop Cleaning, Floor Cleaning, Tank Cleaning, Car & Truck Cleaning',
      'Power Source : Electric Motor',
      'Motor Phase : Single Phase',
      'Voltage : 220V',
      'Hose Length : 10 Metre',
  ]
  
  },
  {
    id: '85',
    name: 'Lawnmower',
    category: 'Garden-Equipments',
    subcategory: null,
    image: LAWNMOWER,
    youtubeLink: null,
    description: ['A lawn mower is a device utilizing one or more revolving blades to cut a grass surface to an even height.',
      'electric lawn mower is good for your lawn as well as for the environment. The central cutting height adjustment can be adjusted to the desired lawn height in six settings. The fold-open 55-litre plastic grass catcher box makes garden work easier for you. The catcher box features a convenient level indicator and can be very easily opened. Thanks to its special downward-facing vanes, the air is guided sideways towards the ground to prevent dirt from being thrown up. For greater comfort, the handlebar can be simply and quickly released and folded down using the Quick Fix clamping device. This allows you to store the machine in a space-saving manner. It comes with a 16-inch (400 mm) cutting width.',
      'Features :-',
      '•	Folding Grass Collection Collection',
      '•	Smooth-Running Wheels',
      '•	Quick-Fix Quick-Release Tensioner',
      '•	Central Cut-Height Adjustment for Lawn Mowers'
    ]
  },
  {
    id: '92',
    name: 'Stubble Mower',
    category: 'Garden-Equipments',
    subcategory: null,
    image: STUBBLEMOWER,
    youtubeLink: null,
    description: ['Engine : 4-Stroke, Petrol Engine',
      'Displacement : 302 cc',
      'Cutting width : 700 mm',
      'Stub Height : 3 – 8 mm',
      'Speed : 3600 RPM (Eng)',
      'Number of Gears : 3 Fwd, 1 Rev',
      'Fuel Tank Capacity : 6.5 L'
    ]
  },
  {
    id: '94',
    name: 'Cub Cadet',
    category: 'Garden-Equipments',
    subcategory: null,
    image: cubcadet1,
    youtubeLink: null,
    description: ['Brand	: Cub Cadet',
      'Power Source	: Gas Powered',
      'Colour :	Yellow',
      'Style	: Push',
      'Item Weight :	35000 Grams',
      'Cutting Width :	22 Inches',
      'Number of Positions :	3',
      '•	A 173cc commercial-grade Kohler engine provides high-performance power, easier starting, extended use and reduced nois',
      '•	22" cutting swath provides a wide reach to quickly trim large areas of grass',
      '•	Ergonomic handle adjusts to 3 positions and a rubber bail grip provides smooth handling and comfort, folds for easy storage',
      '•	Easily lower the cutting height to take on walls and structures or raise it to tackle thick grass and weeds with a 2.375" to 4.375" range',
      '•	Offset deck thoughtfully designed to easily trim around obstacles such as corners and fence lines while walking in a straight line '
    ]
  },
  {
    id: '87',
    name: 'Manual Sprayer',
    category: 'Garden-Equipments',
    subcategory: null,
    image: MANUALsprayerS,
    youtubeLink: null,
    description: ['These sprayers are operated by hand, typically using a pump mechanism. As you pump, you build up pressure inside the sprayer’s tank, which then forces the liquid out through the nozzle when you press the trigger. Manual sprayers come in various sizes 1Ltr to 16Ltr , from small handheld models to larger backpack sprayers.']
  },
  {
    id: '96',
    name: 'Fruit Picker',
    category: 'Garden-Equipments',
    subcategory: null,
    image: fruitpicker,
    youtubeLink: null,
    description: ['•	Hectare Lightweight Mango Fruit Picker/Harvester : A tool for plucking mango and other fruits with long stalks.',
      '•	Included 4 sharp free blades that can be replaced for cutting the stalks.',
      '•	The Hectare Fruit Picker is light and strong, made of composite material.',
      '•	This tool helps you to pick fruits like mango, avocado, star fruit, and more. easily and safely.',
      'Brand	: HECTARE',
      'Colour :	Yellow',
      'Material :	Plastic',
      'Item dimensions L x W x H :	35 x 23 x 4 Centimeters',
      'Item Weight : 281 g'
    ]
  },
  {
    id: '81',
    name: 'Electric Hedge Trimmer',
    category: 'Garden-Equipments',
    subcategory: null,
    image: ELECTRICHEDGETRIMMER,
    youtubeLink: null,
    description: [ 'Input Power :	600 W',
      'Frequency :	50 Hz',
      'Type of Product :	Electric Hedge Trimmer',
      'Model No :	KK-HTE-600',
      'Cutting Length :	510 mm',
      'Voltage :	220-240V',
      'Color	: Multicolor',
      'Cutting diameter :	16mm(Max.)',
      'Fuel Type :	Electric',
      'Engine Type :	Electric',
      '•	Electric Hedge Trimmer is a powerful and efficient gardening tool designed to make hedge trimming tasks easier and more convenient. With its impressive features and reliable performance, it has become a popular choice among both professional gardeners and homeowners',
      '•	One of the standout features of the KK-HTE-600 is its dual-action cutting blades. These blades are designed to move in opposite directions, which significantly reduces vibrations and enhances cutting efficiency',
      '•	Comfort and ease of use are also prioritized in the design of this hedge trimmer. '
    ]
  },
  {
    id: '88',
    name: 'Root Puller',
    category: 'Garden-Equipments',
    subcategory: null,
    image: ROOTPULLER,
    youtubeLink: null,
    description: ['Root Puller is the tool with improved strength and durability for removal of woody plants. This heavy duty tool uses the physics of a lever to give the user a mechanical advantage, improving their ability to grab and uproot any unwanted woody plants.',
      'Usage/Application :	Agriculture',
      'Type	: Hand Operated',
      'Material :	Mild Steel',
      'Weight	: 9 Kg',
      'Length : 4 ft 8 inch',
      'Hand Grip : 	Rubber',
      'Operated By :	One Man',
      'Plant Dia Pulling Capacity	: 1.5 inch'
    ]
  },
  {
    id: '80',
    name: 'Axe',
    category: 'Garden-Equipments',
    subcategory: null,
    image: AXE,
    youtubeLink: null,
    description: [
      '• Easy chopping of firewood, small logs, branches and kindling.',
      '• Optimised blade geometry is designed to give you more one strike splits.',
      '• Forged carbon steel treated blade improves the density and makes the axe more durable.',
      'Handle Material : Wood',
      'Colour : Brown, Black',
      'Head Type : Camp Axe',
    ]
  },
  {
    id: '84',
    name: 'Hedge Shear',
    category: 'Garden-Equipments',
    subcategory: null,
    image: HEDGESHEAR,
    youtubeLink: null,
    description: [
      '• Material: Crafted with durability and comfort in mind, Kraft Seeds Hedge Shears by 10Club has anti-rust coated iron and sharp blades with a soft wooden handles for ease of use.',
      '• Comfortable Grip: Our Hedge Cutter has a smooth wooden handle to prevent hand fatigue and provide excellent grip while working long hours in the garden. Our tool is effective and sturdy and can enhance productivity while gardening!',
      '• Efficient: This stainless steel blade is designed for precision cutting of thorny branches, plant roots, and shrubs, saving time and effort. It also doubles as garden scissors, offering a portable, long-lasting tool for a superior gardening experience.',
      '• Multiple Applications: Great cutting tool to trim lawns, gardens, and overgrown messy bushes and hedges. It can be used to cut flower pedicel, plant stems, potted plants, fruit branches, and more!',
    ]  
  },
  {
    id: '98',
    name: 'Snake Catcher',
    category: 'Garden-Equipments',
    subcategory: null,
    image: snakecatcher,
    youtubeLink: null,
    description: [
      'Suitable For : For Catching Snake',
      'Weight : 800g',
      'Color : Yellow',
      'Material : Aluminium',
      'Country of Origin : India',
      'Length : 6 Ft',
      '• Snake Catcher is a safer way to catch snakes. This snake catcher does not allow the snake to get away and the snake can be released back into the wild.',
      '• The Snake Catcher comes with a hook for hanging in storage and is completely safe for crawling pets.',
      '• It is made of durable material and has a 6-foot reach.',
      '• This snake catcher is a professional-grade snake removal tool.',
      '• It helps you to safely and easily remove snakes from your property, with the help of a step-by-step guide that aids you through the capturing process.',
      '• This product is 6 feet in length, making it one of the longest snake catchers on the market today.',
   ]  
  },
  {
    id: '90',
    name: 'Shade Net',
    category: 'Garden-Equipments',
    subcategory: null,
    image: SHADENET,
    youtubeLink: null,
    description: [
      '• A shade net is a lightweight, knitted fabric that protects people and plants from the sun.',
      '• It can be used in a variety of settings, including gardens, farms, and construction sites.',
      '• Shade nets come in different colors and densities, and can be used for a variety of purposes.',
      '• Available in different sizes.',
    ]  
  },
  {
    id: '99',
    name: 'Grow Bag',
    category: 'Garden-Equipments',
    subcategory: null,
    image: GROWBAG,
    youtubeLink: null,
    description: [
      '• Grow Bags are made from premium eco-friendly recycled materials to reduce your footprint on the environment.',
      '• It will fit anywhere. Garden plant grow bags are great for small apartments, homes with small yards, schools, or offices. Perfect for creating a bright environment.',
      'Advantages:',
      '• Made with high-quality nonwoven fabric, Built-in handles for easy movement.',
      '• Breathable fabric grow bag creates perfect soil conditions, allows soil to cool better on hot days.',
      '• Non-toxic, biodegradable, anti-corrosion, and durable to use.',
      '• Prevents harmful root circling and promotes healthy root pruning.',
      '• Simple structure bags can be easily used for both indoor and outdoor growing.',
      '• It provides water drainage through the soil to eliminate overwatering.',
      '• Keeps plants warmer in winter and cooler in the summer.',
      '• Can be washed, reused, and stored for future use.',
    ]  
  },
  {
    id: '89',
    name: 'Rose Can',
    category: 'Garden-Equipments',
    subcategory: null,
    image: ROSECAN,
    youtubeLink: null,
    description: [
      'Material : Plastic',
      'Color : Green',
      'Type : Hand Sprayer',
      'Usage/Application : It is usually made of metal, ceramic or plastic. At the end of the spout, a "rose" (a device, like a)',
      'Cutting Capacity : 10Ltr',
      'Features : 10Ltr Water holding Capacity - lightweight',
      'Capacity L : 10Ltr',
    ]  
  },
  {
    id: '82',
    name: 'Falcon',
    category: 'Garden-Equipments',
    subcategory: null,
    image: FALCON,
    youtubeLink: null,
    description: [
      'Falcon Garden Tools is a professionally organized company established in 1988 and an eminent Manufacturer and Exporter of Agricultural, Horticultural, Gardening and Forestry equipment and tools. With a view to provide best quality products, we use superior quality materials and latest technology.'
    ]  
  },
  {
    id: '97',
    name: 'Hectare Products',
    category: 'Garden-Equipments',
    subcategory: null,
    image: hectareproducts,
    youtubeLink: null,
    description: [
      'An excellent hand tool for gardening tasks such as planting, creating furrows, cultivating, weeding, and chopping, versatile for a wide range of gardening activities and upkeep.'
    ]  
  },
  {
    id: '93',
    name: 'Weedmat',
    category: 'Garden-Equipments',
    subcategory: null,
    image: Weedmat,
    youtubeLink: null,
    description: [
      '• Weed mats are the best solution to prevent the growth of weeds.',
      '• The Matt material is guaranteed against UV Degradation.',
      'Benefits of Weed mats:',
      '1. Reducing weed growth: One of the primary benefits of using weed mats is that they can effectively block sunlight and prevent weeds from growing. This can reduce the amount of time and effort needed for manual weeding or other weed control methods.',
      '2. Conserving soil moisture: Weed mats can help to prevent evaporation of soil moisture, which can be especially beneficial in dry or arid climates. This can help to reduce the amount of water needed to maintain healthy plants.',
      '3. Preventing erosion: When soil is exposed to wind or rain, it can easily erode and wash away. Weed mats help to anchor the soil in place and prevent erosion.',
      '4. Reducing the need for herbicides: By effectively blocking weed growth, weed mats can reduce the need for herbicides and other chemical weed control methods. This can be especially important for those who prefer organic or natural gardening practices.',
      '5. Promoting healthy plant growth: By reducing competition from weeds, plants can grow more robustly and have better access to nutrients and water.',
  ]  
  },
  {
    id: '86',
    name: 'Manual Weeder',
    category: 'Garden-Equipments',
    subcategory: null,
    image: MANUALWEEDER,
    youtubeLink: null,
    description: [
      'Weeder is a stirrup hoe with a 7-inch long blade, which is recommended to be used for topsoil work in removing weeds with shallow roots.',
      '• 7 inch blade made of stainless steel',
      '• Useful for removing weeds',
      '• Recommended with long pipe',
  ]  
  },
  {
    id: '95',
    name: 'Easycut',
    category: 'Garden-Equipments',
    subcategory: null,
    image: easycut,
    youtubeLink: null,
    description: [
      '• Easy to cut both dry and wet trees',
      '• Made in Taiwan',
      '• Reverse teeth Technology',
      '• Strong blade with SK4 Technology',
      '• Easily Replacable blades',
  ]  
  },
  {
    id: '83',
    name: 'Gardening Tools',
    category: 'Garden-Equipments',
    subcategory: null,
    image: GARDENINGTOOLS,
    youtubeLink: null,
    description: [
      '• Material : The tools are made of iron for strength and durability with metal parts black powder coated for corrosion and rust protection and plastic handles. The pruners are made of Export-quality stainless steel and have precision-ground blades for a sharp edge. The hand gloves are made of plastic.',
      '• Hand Cultivator, Hand Fork, Big Hand Trowel, Small Hand Trowel, Hand Weeder, Garden Hand Gloves, Pruner Cutter',
      '• Comfortable Grip : Essential gardening tools for home use, heavy-duty and professional use with ergonomic handles for planting applications. This garden tools set combo is specially designed for comfortable use with better grip.',
      '• Benefits : Kraft Seeds gardening tools save your time significantly since they are effective and can provide enhanced productivity while doing garden activities! Easy to use, and portable with long life to provide a top-notch garden experience!',
      '• Use Case : This kit for home gardeners is designed to make gardening easy, and you can get the experience of gardening with the right tools in your hand. These tools can be used for digging soil or planting, weeding, and transplanting saplings while protecting your hands from soil intoxicants with protective hand gloves. These agricultural tools can be used from outdoor farming to indoor terrace gardening.'
  ]  
  },
  {
    id: '91',
    name: 'Sprayer',
    category: 'Garden-Equipments',
    subcategory: null,
    image: SPRAYER,
    youtubeLink: null,
    description: ['Sprayer ideal for spraying water, fertilizers, or pest control solutions in large gardens or small farms.']
  },
  {
    id: '100',
    name: 'Honey Bee Hive Jungle Wood Box',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HaneyEquipHoneyBeeHiveJunglewoodBox01,
    youtubeLink: null,
    description: [
      'ISI “A” Type Single Super Eight Framed Honey Bee Hive Suitable for Apis Cerana Indica Bees.',
      'The Hives are fully assembled and are completely made of well-seasoned teak wood.',
      'The Bee Hives are also available in Jungle wood, pine wood or any other wood of customer’s choice.',
      'The color of the hives can be customized as per client requirements.'
  ]
  
  },
  {
    id: '105',
    name: 'Honey Bee Hive Box',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipHoneyBeeHive02,
    youtubeLink: null,
    description: [
      'Bee Hive with two full depth supers.',
      'The Hives are fully assembled using screws and are made of pine wood.',
      'The Bee Hives are also available in Jungle wood, teak wood or any other wood of customer’s choice.',
      'The Hives are paraffin wax dipped for extended life.',
      'Hives with medium and shallow supers are also available.'
  ]
  
  },
  {
    id: '101',
    name: 'Honey Nano Bee Hive Box',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HaneyEquipHoneyNanoBeeHiveBox01,
    youtubeLink: null,
    description: [
      'Single Super Six Framed Honey Bee Hive Suitable for Apis Cerana Indica Bees.',
      'The Hives are fully assembled and are made of Jungle wood.',
      'The color of the hives can be customized as per client requirements.'
  ]
  
  },
  {
    id: '108',
    name: 'Queen Gate Plastic',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipQueenGate03,
    youtubeLink: null,
    description: ['Queen gate is a barrier usually kept at the entrance of the bee hive for the queen not to go out of the hive.']
  },
  {
    id: '107',
    name: 'Queen Gate',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipQueenGate02,
    youtubeLink: null,
    description: ['Queen gate is a barrier usually kept at the entrance of the bee hive for the queen not to go out of the hive.']
  },
  {
    id: '106',
    name: 'Iron Stand',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipProductsIronStand01,
    youtubeLink: null,
    description: ['Iron Stand made of strong GI Pipe with protective coating of paint for extended life. Different sizes and models are available.']
  },
  {
    id: '104',
    name: 'Bee Veil',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipBeeVeil01,
    youtubeLink: null,
    description: [
      'Bee veil made of fabric and wire mesh.',
      'These veils keep your face and neck safe from attacks from honeybees.',
      'Bee veil made of plastic hat and wire mesh.',
      'These veils keep your face and neck safe from attacks from honeybees.'
  ]
  
  },
  {
    id: '103',
    name: 'Bee Smoker',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HoneyEquipBeeSmoker01,
    youtubeLink: null,
    description: [
      'Easy to use bee smoker made of good quality durable stainless steel material.',
      'Bellows used are of good quality and are replaceable.',
      'Easy to use bee smoker made of Galvanized Iron.',
      'Bellows used are of good quality and are replaceable.'
  ]
  
  },
  {
    id: '102',
    name: 'Honey Extractor Galvanized Iron Sheet',
    category: 'Honey-Equipments',
    subcategory: null,
    image: HaneyEquipHoneyextractorsGalvanizedIronSheet,
    youtubeLink: null,
    description: [
      'Four frame honey extractor suitable for ISI ‘A’ type super frames.',
      'These extractors are sturdy and simple mechanical devices for extracting honey from honey combs.',
      'Honey extractors made of Stainless steel and Galvanized iron Sheet are available.',
      'The extractors can be made for 2 frames, 4 frames, 6 frames and 8 frames model.',
      'Honey extractors for Langstroth/Australian frames are also available in combination of number of frames and honey holding capacity.'
  ]
  
  },
  {
    id: '109',
    name: 'Cute Honey Bee Stickers',
    category: 'Honey-Equipments',
    subcategory: null,
    image: cutehoneybeestickersforcaroranywherestickerbumperstickeralibees782517_grande,
    description: ['Cute Honey Bee Stickers for cars or any surface, a fun way to show your love for bees and honey!']
  },
  {
    id: '116',
    name: 'Finolex Pipes',
    category: 'Irrigation',
    subcategory: null,
    image: finolexpipes,
    youtubeLink: null,
    description: [
      '• Material : Finolex offers an extensive range of agriculture PVC-U pressure pipes and fittings. These pipes and fittings are designed specifically for varied pressure and gravity flow applications in agriculture, irrigation, swimming pool applications, and domestic water supply including potable water supply.',
      '• Advantages : Finolex agriculture PVC-U pressure pipes offer significant advantages. They are light in weight, easy for transportation and installation, and have a high flow rate. High resistance to chemicals and corrosion ensures an extended product life.'
  ]  
  },
  {
    id: '118',
    name: 'Sprinkler',
    category: 'Irrigation',
    subcategory: null,
    image: sprinkler,
    youtubeLink: null,
    description: [
      '• Material : Sprinkler Irrigation is a method of applying irrigation water which provides a rainfall-like effect. Water is distributed through a system of pipes usually by pumping. It is then sprayed into the air and the entire soil surface is irrigated through spray heads so that it breaks up into small water droplets that fall on the ground.',
      '• Advantages : Sprinklers provide efficient coverage for small to large areas and are suitable for use on all types of farmlands. It is also adaptable to nearly all types of irrigable soils as sprinklers are available in a wide range of discharge capacities.'
  ]  
  },
  {
    id: '115',
    name: 'Drip Irrigation Items',
    category: 'Irrigation',
    subcategory: null,
    image: dripitems,
    youtubeLink: null,
    description: [
      '• Material : Drip Pipes are manufactured from virgin special grade polyethylene plastic granules. The lateral is UV stabilized. Cylindrical droppers are used with the long and wide path.',
      '• Features : Inline laterals are available with different wall thickness, size, and flow rate. Inline drip lines are used above ground installation as well as subsurface installation.'
  ]  
  },
  {
    id: '114',
    name: 'Water Tank',
    category: 'Irrigation',
    subcategory: null,
    image: WATERTANK,
    youtubeLink: null,
    description: [
      '• Material : Sunrise branded plastic water storage tanks in the capacities ranging from 200 ltrs to 5000 ltrs in different designs as well as different colours viz, Cylindrical vertical tanks, Horizontal tanks and Open top multipurpose tank.',
      '• Features : Sunrise produces high-quality durable water tanks made with premium LLDPE plastic which is safe and durable. The tanks are available in various sizes with multiple layers and different shapes for multiple types of usages.'
  ]  
  },
  {
    id: '112',
    name: 'Submersible Pump',
    category: 'Irrigation',
    subcategory: null,
    image: SUBMERSIBLEPUMP,
    youtubeLink: null,
    description: [
      '• Material : Submersible pump is used for draining water: for example, for reducing groundwater, pumping groundwater to be used as drinking or fire-fighting water, pumping dirty water, and draining wastewater.'
  ]  
  },
  {
    id: '110',
    name: 'Monobloc Pump',
    category: 'Irrigation',
    subcategory: null,
    image: MONOBLOCPUMP,
    youtubeLink: null,
    description: [
      '• Material : A monoblock pump is an end suction pump that consists of a partially opened or closed impeller. It is a horizontal single-segment pump wherein all the revolving parts are fitted on a single shaft. Unlike couplings and belt drives, monoblock pumps have the unique feature of eliminating losses.'
  ]
  
  },
  {
    id: '111',
    name: 'Slurry Pump',
    category: 'Irrigation',
    subcategory: null,
    image: SLURRYPUMP,
    youtubeLink: null,
    description: [
      '• Material : A slurry pump is a type of pump designed for pumping liquid containing solid particles. Slurry pumps change in design and construction to adjust to multiple types of slurry, which vary in concentration of solids, size of solid particles, shape of solid particles, and composition of solution. Slurry pumps are more robust than liquid pumps; they have added sacrificial material and replaceable wear parts to withstand wear due to abrasion.'
  ]
  
  },
  {
    id: '117',
    name: 'Multistage Pump',
    category: 'Irrigation',
    subcategory: null,
    image: multistagepump,
    youtubeLink: null,
    description: [
      '• Material : Multistage pumps are defined as pumps in which the fluid flows through several impellers fitted in series. The head of a single-stage centrifugal pump is largely governed by the type of impeller and the circumferential speed.'
  ]
  
  },
  {
    id: '113',
    name: 'Water Pump',
    category: 'Irrigation',
    subcategory: null,
    image: WATERPUMP,
    youtubeLink: null,
    description: ['Water Pump used for transferring water to various locations, ideal for irrigation systems and general water management.']
  },
  {
    id: '129',
    name: 'Easycut',
    category: 'Other-Equipments',
    subcategory: null,
    image: easycut1,
    youtubeLink: null,
    description: ['Easycut tool for precision cutting in gardens and fields, ideal for small-scale farming operations.']
  },
  {
    id: '120',
    name: 'Generator',
    category: 'Other-Equipments',
    subcategory: null,
    image: GENERATOR,
    youtubeLink: null,
    description: ['Generator for providing backup power to agricultural equipment and operations during power outages.']
  },
  {
    id: '123',
    name: 'Stubble Mower',
    category: 'Other-Equipments',
    subcategory: null,
    image: STUBBLEMOWER1,
    youtubeLink: null,
    description: [
      'Engine : 4-Stroke, Petrol Engine',
      'Displacement : 302 cc',
      'Cutting width : 700 mm',
      'Stub Height : 3 – 8 mm',
      'Speed : 3600 RPM (Eng)',
      'Number of Gears : 3 Fwd, 1 Rev',
      'Fuel Tank Capacity : 6.5 L'
  ]
  
  },
  {
    id: '125',
    name: 'Blower',
    category: 'Other-Equipments',
    subcategory: null,
    image: blower,
    youtubeLink: null,
    description: [
      '• Blowers ensure that every droplet of pesticide, fungicide, or fertiliser finds its intended destination. This precision minimises waste and maximises effectiveness, fostering healthier crops and higher yields.',
      '• Comfortable Operation : Thanks to the single-handed multi-function handle, you can control your power tool easily and reliably with one hand. Because all relevant control elements are integrated in the handle, it can be operated by both right-handed and left-handed users.',
      '• For More Pleasant Work : Vibrations can occur when you put your power tools through their paces, and these are particularly noticeable in the grip area. The buffer and spring elements of the STIHL anti-vibration system reduce the transmission of engine vibrations to the handle area and therefore to your body. The result is that your power tool is more comfortable to hold and your work is much less tiring.'
  ]
  
  },
  {
    id: '132',
    name: 'Mist Blower',
    category: 'Other-Equipments',
    subcategory: null,
    image: mistblower,
    youtubeLink: null,
    description: ['Mist Blower for spraying liquid products over crops and plants, ideal for pest control and fertilization.']
  },
  {
    id: '122',
    name: 'MS-5000 Mini Gas Fogger',
    category: 'Other-Equipments',
    subcategory: null,
    image: MS5000MiniGasFogger,
    youtubeLink: null,
    description: ['MS-5000 Mini Gas Fogger for pesticide spraying in agricultural fields, designed for precision and ease of use.']
  },
  {
    id: '130',
    name: 'Fogging Machine',
    category: 'Other-Equipments',
    subcategory: null,
    image: foggingmachine,
    youtubeLink: null,
    description: [
      'The smoke fog duel-use machine is very advanced agriculture machinery. Unlike the traditional fogging machine, it can not only be used for urban sanitation, large-scale epidemic prevention, and brake insect sterilization in public places but also can be used for pesticide spraying of fruit trees, rice, corn, and other crops.',
      'In order to improve the utilization rate of agriculture machinery and reduce operating costs, under the premise of ensuring agriculture technology requirements, it has a wide range of versatility and a high degree of comprehensive utilization & machine has the advantage of easy start, easy maintenance, best performance & stable quality.',
      'Specifications :',
      '• Displacement : 48 cc',
      '• Flow rate : 2.0 L/Min',
      '• Chemical tank capacity : 2.5 Ltr',
      '• Medicine box capacity : 8 L X 2'
  ]  
  },
  {
    id: '131',
    name: 'Karcher Vacuum Cleaner',
    category: 'Other-Equipments',
    subcategory: null,
    image: karchervaccumcleaner,
    youtubeLink: null,
    description: ['Karcher Vacuum Cleaner for efficient cleaning of surfaces in agricultural and industrial environments.']
  },
  {
    id: '119',
    name: 'Coconut Husk Peeler',
    category: 'Other-Equipments',
    subcategory: null,
    image: COCONUTHUSKPEELER,
    youtubeLink: null,
    description: [
      'Heavy-duty construction : For long-lasting reliability',
      'Sharp, specialized blade : For effortless husk removal',
      'Ergonomic design : For comfortable, fatigue-free use',
      'Perfect for : Home and professional applications'
  ]  
  },
  
  {
    id: '121',
    name: 'Ladder',
    category: 'Other-Equipments',
    subcategory: null,
    image: LADDER1,
    youtubeLink: null,
    description: [
      'Ladders for different uses, including agricultural, tree climbing, heavy machinery, maintenance, and domestic use.',
      'Their ladders are made from durable aluminum and are designed to be lightweight, easy to transport, and stable.'
  ]
  
  },
  
  
  {
    id: '124',
    name: 'Weedmat',
    category: 'Other-Equipments',
    subcategory: null,
    image: Weedmat1,
    youtubeLink: null,
    description: ['Weedmat for preventing weed growth in agricultural fields and gardens while allowing water and air circulation.']
  },
 
  {
    id: '126',
    name: 'Boiler',
    category: 'Other-Equipments',
    subcategory: null,
    image: boiler,
    youtubeLink: null,
    description: [
      'Material : Mild Steel',
      'Usage/Application : Hot Water',
      'Surface Finish : SS Glading',
      'Product Type : Hot Water Boiler'
  ]
  
  },
  {
    id: '127',
    name: 'Coconut Climber',
    category: 'Other-Equipments',
    subcategory: null,
    image: coconutclimber,
    youtubeLink: null,
    description: [
      'Material : Stainless steel rods',
      'Usage : Manual climbing of coconut trees',
      'Adjustability : Can be used on trees of different sizes by changing the wire rope',
      'Safety Features : Some climbers come with a harness and safety belt'
  ]
  
  },
  {
    id: '128',
    name: 'Cylinder Trolley',
    category: 'Other-Equipments',
    subcategory: null,
    image: cylindertrolley,
    youtubeLink: null,
    description: ['Cylinder Trolley designed for easy transportation of gas cylinders in agricultural and industrial settings.']
  },
 
  
  
  {
    id: '133',
    name: 'Rain Cover',
    category: 'Other-Equipments',
    subcategory: null,
    image: raincover,
    youtubeLink: null,
    description: [
      'Material : High-quality, eco-friendly EVA (ethylene-vinyl acetate)',
      'Features : Lightweight, waterproof, and long-lasting',
      'Size : Universal size, long length for full coverage',
      'Gender : Suitable for both men and women',
      'Uses : Perfect for outdoor activities, travel, or daily commutes',
      'Protection : Dependable protection from wet weather conditions'
  ]
  
  },
  {
    id: '134',
    name: 'Shade Net',
    category: 'Other-Equipments',
    subcategory: null,
    image: shadenet,
    youtubeLink: null,
    description: ['Shade Net for providing protection against excessive sunlight, ensuring healthier plant growth in greenhouses and farms.']
  },
  {
    id: '135',
    name: 'Solar Trap',
    category: 'Other-Equipments',
    subcategory: null,
    image: solartrap,
    youtubeLink: null,
    description: [
      'Solar insect trap is a device for pest control. The device gets charged in day time using sunlight and automatically switches on at dawn and dusk to trap harmful insects.',
      'Specification :',
      'Product Type : Insect Trap',
      'Brand : Generic ( Made in India )',
      'Material : Virgin LLDPE',
      'Pest Control Type : Drowning Method',
      'Lamp : UV LED'
  ]
  
  },
  {
    id: '141',
    name: 'Power Tiller',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: powertiller,
    youtubeLink: null,
    description: [
      'POWER TILLER 130-DI',
      'Engine : 13HP -2400 RPM',
      'Engine Type : 4 stroke direct injection/ water cooled',
      'Fuel Consumption : 190 gms/hp/hour',
      'Fuel : Diesel',
      'Fuel Tank Capacity : 11 litres',
      'DriveSide driveGear : 6 Forward & 2 Reverse',
      'No Of Blades : 18'
    ]  
  },
  {
    id: '143',
    name: 'Tractor 20HP-60HP',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: tractor20hp60hp,
    youtubeLink: null,
    description: [
      'Sonalika Tractors is a leading tractor brand known for being India\'s No.1 export brand. It offers a wide range of heavy-duty tractors.',
      'Sonalika tractor HP ranges from 20 to 120 HP. These tractors, available in 2WD and 4WD, are designed for various farming tasks, such as pulling heavy loads, puddling, and ploughing.',
      'Additionally, this brand has entered the electric world with Sonalika Tiger Electric, India’s first field-ready electric tractor.'
  ]  
  },
  {
    id: '140',
    name: 'Rotary Tiller',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: ROTARYTILLER,
    youtubeLink: null,
    description: [
      'Engine : 5HP-7.3 HP',
      'Engine Capacity : 296 cc',
      'Engine Type : 4 Stroke',
      'Fuel : Diesel',
      'Fuel Tank Capacity : 3.0 L - 5.0 L'
  ]
  
  },
  {
    id: '144',
    name: 'Transplanter',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: transplanter,
    youtubeLink: null,
    description: [
      'Brand : Mahindra',
      'Engine Type : Air cooled, single cylinder, 4 stroke',
      'Fuel Tank Capacity : 3.5 L',
      'Weight : 180 Kg (Operating)',
      'Engine Model : MF168 FB',
      'Displacement : 196 cm³',
      'Type : 4 rows',
      'Model Number : MP461',
      'Rated Output : 3.72 kW at 1700 r/min',
      'Fuel : Petrol',
      'Number of Wheels : 2',
      'Wheel Type : Rubber lug wheel'
  ]
  
  },
  {
    id: '142',
    name: 'Reaper',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: reaper,
    youtubeLink: null,
    description: [
      'Engine Fuel Type : Diesel',
      'Cutter Bar Width : 5 Feet',
      'Capacity : 0.5 acres/hr',
      'Model Name / Number : 7 PR',
      'Power Type : 7 HP',
      'No. Of Blades : 4 Blades'
  ]
  
  },
  {
    id: '136',
    name: 'Conoweeder',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: CONOWEEDER,
    youtubeLink: null,
    description: [
    'Colour : Orange',
    'Material : Mild Steel',
    'Working Width : 140-160 mm',
    'Additional Details : Handle Size: 25x430 mm',
    'Dimensions : 320x120x65 mm',
    'Weight : 4.50-5 kg'
]

  },
  {
    id: '138',
    name: 'Manual Seeder',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: MANUALSEEDER,
    youtubeLink: null,
    description: [
      'Seeder has reduced the need for transplantation and hours of human labor, which would literally break the backs of farmers involved in sowing paddy seeds in the field.',
      'With a single operator effort, it can cover 8 rows of 20 cm row-to-row spacing at once.',
      '• Product Type : Manual Seeder',
      '• Material : Plastic',
      '• Automation : Manual',
  ]
  
  },
  {
    id: '137',
    name: 'Drum Seeder',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: DRUMSEEDER,
    youtubeLink: null,
    description: ['Drum Seeder for uniform sowing of seeds in paddy fields, designed for high productivity and minimal labor.']
  },
  {
    id: '139',
    name: 'Nursery Tray',
    category: 'Paddy-Equipments',
    subcategory: null,
    image: NURSERYTRAY,
    youtubeLink: null,
    description: [
      'Features :',
      '• Helps in saving water and fertilizers.',
      '• Seedlings are planted in an organized manner helping with weed management.',
      '• This method provides 25% more yield than the conventional method.',
      '• 50% of prime cost is saved compared to the conventional method.',
      '• More land area can be covered.',
      '• Minimum labor is required.',
      '• Reusable for several cycles.',
      '• High durability and strength.',
      '• Material : Plastic',
      '• Shape : Rectangular',
      '• Size (L x W x H) : 24 x 12 x 1 Inches (60.96 x 30.48 x 2.54 cm)',
      '• Colour : Black',
  ]
  
  },
  {
    id: '145',
    name: 'Baler',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: BALER,
    youtubeLink: null,
    description: [
      '• A baler is a farm machine that compresses cut and raked crops into bales that are easier to handle, transport, and store.',
      '• Balers are used to compact crops such as hay, cotton, flax straw, salt marsh hay, or silage.',
      '• MODEL : JUMBO 1209',
      '• Bale length : 93 cm',
      '• Bale diameter : 60 cm',
      '• Bale weight : 28-32 kg',
      '• No. of rollers : 12',
      'Features :',
      '• Saves Fuel & Time',
      '• Low Maintenance Cost',
      '• Suitable for wet and dry land',
      '• Robust & Sturdy structure',
      '• Reduces Load on tractors',
      '• Maintains Moisture Level of Soil',
      '• Ideal for Crops like Cotton, wheat, Ground Nut, Sugarcane, Rice, Vegetables, etc.',
  ]
  
  },
  {
    id: '153',
    name: 'Rotovator',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: rotovator,
    youtubeLink: null,
    description: [
      '• The ROTOVATOR is a medium and hard soil rotary tiller with combined features of its predecessors Light and Smart.',
      '• Available in multiple drives, speeds, and blade options; it can produce superior seedbeds for both dry and wet cultivation.',
      '• Speed : Regular Multi Speed',
      '• Overall Width (Feet) : 4 feet to 6 Feet',
      '• Number of Blades : 24-42',
      '• Model Name/Number : Heavy Duty',
      '• Brand : Rotoking',
      '• Blade Type : L Type',
      '• Tractor Power hp : 30-70 HP',
  ]
  
  },
  {
    id: '151',
    name: 'Spring Cultivator',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: SPRINGCULTIVATOR,
    youtubeLink: null,
    description: [
      'Features:',
      '• Secondary tillage implement for aerating & loosening of soil.',
      '• Better pulverization for smooth seed bed preparation.',
      '• Best suitable for use in stony & rooty soil conditions.',
      '• Spring provides the damping effect like if one tine is obstructed, the other continues to work at proper depth.',
      '• Heavy duty springs with 10 mm WD for overcoming obstruction like stones/hooks etc.',
  ]
  
  },
  {
    id: '147',
    name: 'Duckfoot Cultivator',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: DUCKFOOTCULTIVATOR,
    youtubeLink: null,
    description: [
      '• Duck Foot Cultivator: The duck foot cultivator manipulates the soil at shallow to medium depth to prepare seedbed for sowing.',
      '• It consists of a main frame, three-point hitch system, and shank to which the sweeps (soil working blades) are attached.',
      '• Type : Duck Foot',
      '• No Of Tynes : 5-9',
      '• Material : Iron',
      '• Application : Agriculture',
      '• Power Required : 30HP-55HP',
  ]
  
  },
  {
    id: '148',
    name: 'Mobile Shredder',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: MOBILESHREDDER,
    youtubeLink: null,
    description: [
      '• It\'s specially designed to tackle different types of crops and field conditions with longer life.',
      '• There are mainly two systems, Feeding & Shredding.',
      '• Removes root while chopping off stalk.',
      '• Brand : Rotoking',
      '• Material : Mild Steel',
      '• Gear Box : Multi Speed',
      '• Weight : 605 cm',
      '• Height : 1415 cm',
      '• Breadth : 842 cm',
      '• Length : 1700 cm',
      '• PTO Speed : 1000 RPM',
      '• Tractor HP : 55 HP',
      '• No Of Disc : 2',
      '• No Of Blade : Fly Wheel Blade',
  ]
  
  },
  {
    id: '149',
    name: 'Mulcher',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: MULCHER,
    youtubeLink: null,
    description: [
      '• Brand : Rotoking',
      '• Usage/Application : Agriculture & Farming',
      '• Model Name/Number : Rrm 180',
      '• Material : Mild Steel',
      '• Gear Box : Single',
      '• Weight : 565 Kg',
      '• Height : 100 cm',
      '• Breadth : 110 cm',
      '• Length : 200 cm',
      '• PTO Speed : 540 RPM',
      '• Tractor Power : 55 HP',
      '• No Of Blade : Y-36',
      '• Implement Power : 45 HP',
  ]
  },
  
  {
    id: '152',
    name: 'Sugarcane Stubble Shaver',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: SUGARCANESTUBBLESHAVER,
    youtubeLink: null,
    description: [
      '• Material : Iron',
      '• Power : 35',
      '• Application : For sugarcane cultivation',
      '• Brand : Rotoking',
      '• Suitable For : Extremely suitable for a tractor of 35 HP to 60 HP',
      'Features Of Rotoking Sugarcane Stubble Shaver :',
      '  • Tractor & PTO operated disc type stubbly shaver available with a depth wheel to control the depth of operation.',
      '  • Having unique cutting mechanism.',
  ]
  
  },
  {
    id: '150',
    name: 'Post Hole Digger',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: POSTHOLEDIGGER,
    youtubeLink: null,
    description: [
      '• Material : Mild Steel',
      '• Type of Hole Diggers : Farm Equipment',
      '• Brand : Rotoking',
      '• Condition : New',
      '• Application : For Drill/Dig holes of various sizes and depths in soil',
      'Features Of Rotoking Post Hole Digger :',
      '  • Our product is easy to operate & suitable for all types of soil.',
      '  • Our digger can successfully dig the hole of 9, 12, 18, 24, 30 and 36 inches in less than a minute.',
      '  • The digging is faster and less garbage of soil is generated and this soil will be used around the pit and pit can be filled easily.',
  ]
  
  },
  {
    id: '146',
    name: 'Dozer Backhoe & Loader',
    category: 'Paddy-Equipments',
    subcategory: 'Tractor-Equipments',
    image: DOZERBACKHOELOADER,
    youtubeLink: null,
    description: ['Dozer Backhoe & Loader for excavation and earth-moving tasks, ideal for paddy field operations and land leveling.']
  },
  {
    id: '154',
    name: '0.5 HP Green Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: GREENPEPPERPEELINGMACHINE05HP,
    youtubeLink: null,
    description: [
      'Pepper peeling machines are used to peel pepper. They can be fully automated, from transporting raw grains to peeling.',
      'Benefits of Pepper Peeling Machines :',
      '  • Dry peeling: No need to add water, which can reduce labor costs.',
      '  • Stable operation: Good quality and low maintenance cost.',
  ]
  
  },
  {
    id: '1541',
    name: '0.5 HP Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: PEPPERPEELINGMACHINE05hp,
    youtubeLink: null,
    description: [
      'Pepper peeling machines are used to peel pepper. They can be fully automated, from transporting raw grains to peeling.',
      'Benefits of Pepper Peeling Machines :',
      '  • Dry peeling: No need to add water, which can reduce labor costs.',
      '  • Stable operation: Good quality and low maintenance cost.',
  ]
  },
  {
    id: '156',
    name: '1 HP Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: PEPPERPEELINGMACHINE1HP,
    youtubeLink: null,
    description: [
      'Pepper peeling machines are used to peel pepper. They can be fully automated, from transporting raw grains to peeling.',
      'Benefits of Pepper Peeling Machines :',
      '  • Dry peeling: No need to add water, which can reduce labor costs.',
      '  • Stable operation: Good quality and low maintenance cost.',
  ]
  },
  {
    id: '1542',
    name: 'Bunch Separator',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: BUNCHSEPERATOR1,
    youtubeLink: null,
    description: [
      '• Pepper bunch Separator helps detach and segregate individual pepper from the bunch.',
      '• Manual process involves thrashing the whole bunch against a wooden plank or thrashing using hand-held iron rods.',
      '• Manual process is labor intensive and can cause damages to the pepper.',
  ]
  
  },
  {
    id: '155',
    name: '1 HP CAT 960 Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: CAT960PEPPERPEELINGMACHINE1HP,
    youtubeLink: null,
    description: [
      'Pepper peeling machines are used to peel pepper. They can be fully automated, from transporting raw grains to peeling.',
      'Benefits of Pepper Peeling Machines :',
      '  • Dry peeling: No need to add water, which can reduce labor costs.',
      '  • Stable operation: Good quality and low maintenance cost.',
  ]
  },
  {
    id: '157',
    name: '2HP CAT 970 Pepper Peeling Machine',
    category: 'Pepper-Equipments',
    subcategory: null,
    image: CAT9702HP,
    youtubeLink: null,
    description: [
      'Pepper peeling machines are used to peel pepper. They can be fully automated, from transporting raw grains to peeling.',
      'Benefits of Pepper Peeling Machines :',
      '  • Dry peeling: No need to add water, which can reduce labor costs.',
      '  • Stable operation: Good quality and low maintenance cost.',
  ]
  },
  {
    id: '164',
    name: 'Drill M0600B',
    category: 'Power-Tools',
    subcategory: null,
    image: drillM0600B,
    youtubeLink: null,
    description: [
      'MAKITA M0600B DRILL',
      '10 mm (3/8″) 350 W Variable Speed Reversing Drill with Keyed Chuck',
      '• High performance and durability at less expense',
      '• Variable speed trigger allows operator to adjust speed based on materials or application',
      '• Powerful 350 W motor for continuous operation',
      '• In-line positive grip design maximises power thrust',
      '• Compact and lightweight for reduced operator fatigue',
      '• Recessed lock-on button for continuous use',
      '• Reversing switch lever is conveniently located',
      '• Double insulated',
  ]
  
  },
  {
    id: '160',
    name: 'Hammer Drill',
    category: 'Power-Tools',
    subcategory: null,
    image: HAMMERDRILL,
    youtubeLink: null,
    description: [
      'MAKITA HAMMER DRILL M0801',
      '• High performance and durability at less expense',
      '• Powerful 500 W motor with 0-3,200 RPM & 0-48,000 BPM for the most demanding applications',
      '• In-line positive grip design maximises power thrust',
      '• Recessed lock-on button for continuous use',
      '• Reversing switch is conveniently located',
      '• 360° swivel side handle',
      '• Variable speed control dial enables user to match the speed to the application',
      '• 2-mode operation for “Rotation Only” or “Hammering with Rotation” for multiple applications',
      '• Double insulated',
  ]
  
  },
  {
    id: '158',
    name: 'Angle Grinder',
    category: 'Power-Tools',
    subcategory: null,
    image: ANGLEGRINDER,
    youtubeLink: null,
    description: [
      'ANGLE GRINDER 9553B',
      '• Powerful 710 W motor delivers 11,000 RPM for high output power',
      '• Compact design with small diameter barrel and lightweight for added comfort',
      '• Protective zig-zag varnish seals the armature from dust and debris for longer tool life',
      '• Dust-proof ball bearing on spindle for durability',
      '• Spiral bevel gears for smoother rotations and a more efficient transfer of energy',
      '• Toggle switch for easy operation',
      '• Wheel cover protects operator from accidental scattering of broken wheel or workpiece with minimal wheel cover movement',
      '• Push button spindle lock for operator convenience',
      '• Gear housing can be rotated to suit most cutting and grinding applications',
      '• Double insulated',
  ]
  
  },
  {
    id: '161',
    name: 'Impact Driver',
    category: 'Power-Tools',
    subcategory: null,
    image: IMPACTDRIVER,
    youtubeLink: null,
    description: [
      '• Compact design for perfect handling',
      '• Externally accessible carbon brush',
      '• No harm to the wrist when a screw is seated',
      '• Enables to drive screws even without pre-drilling pilot holes',
      '• Variable speed',
      '• Reversing switch',
      '• Double insulated',
  ]
  
  },
  {
    id: '163',
    name: 'Chain Filing Tool',
    category: 'Power-Tools',
    subcategory: null,
    image: chainfiling,
    youtubeLink: null,
    description: [
      'Universal filing tool for sharpening all saw chains, circular saw blades for clearing saws and hedge trimmer blades.',
      'Complete with swivel head for all Oilomatic saw chains and two shaped grinding wheels.',
      'Also suitable for sharpening saw chains.'
  ]
  
  },
  {
    id: '166',
    name: 'Vacuum Cleaner',
    category: 'Power-Tools',
    subcategory: null,
    image: vaccumcleaner,
    youtubeLink: null,
    description: [
      'Cleaning the floor and other areas of your space becomes effortless with the Karcher WD 1S Classic 1300 W Vacuum Cleaner.',
      'It is capable of removing wet and dry dirt and accidental spills in a matter of seconds with its suction power of upto 200 air watts.',
      'Also, it can be used as a blower in spaces where you cannot vacuum, such as the backyard or home garden.',
      'Moreover, its large dust container has a capacity of up to 18 L to collect dust and dirt without the need to constantly empty it.'
  ]
  
  },
  {
    id: '159',
    name: 'Electric Handheld Pruner',
    category: 'Power-Tools',
    subcategory: null,
    image: ELECTRICHANDHELDPRUNER,
    youtubeLink: null,
    description: ['Electric Handheld Pruner for effortless pruning of plants and trees, reducing the physical strain of manual cutting.']
  },
  {
    id: '162',
    name: 'Makita Electric Brushcutter',
    category: 'Power-Tools',
    subcategory: null,
    image: MAKITAELECTRICBRUSHCUTTER,
    youtubeLink: null,
    description: ['Makita Electric Brushcutter for efficient trimming and cutting of grass, weeds, and shrubs.']
  },
  {
    id: '165',
    name: 'Makita Cordless Chainsaw',
    category: 'Power-Tools',
    subcategory: null,
    image: makitaCORDLESSCHAINSAW,
    youtubeLink: null,
    description: ['Makita Cordless Chainsaw for easy and portable cutting, designed for small to medium-sized jobs.']
  },
  {
    id: '176',
    name: 'Sunkraft SKPSP22',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFT_SKPSP22,
    youtubeLink: null,
    description: [
      'Engine Power : 4.2 Kw / 6.5 HP',
      'Engine RPM : 3600',
      'Discharge : 20-30 Lpm',
      'Pressure : 20-45 Kg / Cm2',
      'Suction & Delivery Hose : 3 Mtr'
  ]
  
  },
  {
    id: '173',
    name: 'Sunkraft SKPSP30',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFTSKPSP30,
    youtubeLink: null,
    description: [
      'Engine Power : 4.2 Kw / 6.5 HP',
      'Engine RPM : 3600',
      'Discharge : 20-45 Lpm',
      'Pressure : 20-45 Kg / Cm2',
      'Suction & Delivery Hose : 3 Mtr'
  ]
  
  },
  {
    id: '175',
    name: 'Sunkraft SKPS18',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFT_SKPS18,
    youtubeLink: null,
    description: [
      'Engine Power : 1.5 - 2 HP',
      'Engine RPM : 800-1200',
      'Discharge : 13-22 Lpm',
      'Pressure : 20-45 Kg / Cm2',
      'Suction & Delivery Hose : 5 Mtr'
  ]
  
  },
  {
    id: '172',
    name: 'Sunkraft SK768',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFTSK768,
    youtubeLink: null,
    description: [
      'Engine Type : Air Cooled, 2 Stroke',
      'Engine Displacement : 34cc',
      'Pump Pressure : 15-25 Kg / Cm2',
      'Discharge : 5-8 Lpm'
  ]
  
  },
  {
    id: '174',
    name: 'Sunkraft SK868',
    category: 'Sprayers',
    subcategory: null,
    image: SUNKRAFT_SK868,
    youtubeLink: null,
    description: [
      'Engine Type : Air Cooled, 4 Stroke',
      'Engine Displacement : 39cc',
      'Pump Pressure : 15-25 Kg / Cm2',
      'Discharge : 5-8 Lpm'
  ]
  
  },
  {
    id: '178',
    name: 'Ultima Power Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: ULTIMAPOWERSPRAYER,
    youtubeLink: null,
    description: [
      'HTP Power Sprayer With Different Combination Such as XR550 Engine With UL22 Pump or SK22 Pump'
  ]
  
  },
  {
    id: '170',
    name: 'Kisankraft KKPS22',
    category: 'Sprayers',
    subcategory: null,
    image: KISANKRAFTKKPS22,
    youtubeLink: null,
    description: [
      'Features',
      '• Long and wider spray reach',
      '• Durable & strong body',
      '• Ensures uniform spraying patterns',
      '• Long operational life',
      '• Crafted and engineered for smoothness and wear resistance',
      'Specification:',
      '• HTP Sprayer: KK-PSP-22',
      '• Engine: KK-PE4-163 (Petrol)',
      '• Displacement: 163 cc',
      '• Chemical Tank Capacity: 18L',
      '• Speed (RPM): 800-1200',
      '• Output pressure: 10-40 kg/cm2'
  ]
  
  },
  {
    id: '171',
    name: 'Kisankraft KKPS30',
    category: 'Sprayers',
    subcategory: null,
    image: KISANKRAFTKKPS30,
    youtubeLink: null,
    description: [
      'HTP Sprayer: KK-30CI3',
      '• Engine: KK-PE4-203 (Petrol)',
      '• Displacement: 196 cc'
  ]
  
  },
  {
    id: '169',
    name: 'GX80+SK22 Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: GX80SK22,
    youtubeLink: null,
    description: [
      'Material: Iron',
      'Sprayer Type: HTP',
      'Brand: Honda',
      'Pump Type: HTP',
      'Model Name/Number: GX80',
      'Fuel Type: Petrol',
      '• Honda HTP power sprayer consists of engine, HTP pump, prime mover, tank, agitator, air-chamber, pressure gauge, pressure regulator, and nozzle and pipe reel stand wheel.',
      '• Pipe and spray gun will be separate.',
      '• Honda HTP power sprayer is a heavy duty and efficient sprayer to the height up to 50 feet.'
  ]
  
  },
  {
    id: '167',
    name: '168F HTP Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: HTPSPRAYER168F,
    youtubeLink: null,
    description: ['168F HTP Sprayer for efficient spraying of pesticides and fertilizers in large-scale farming operations.']
  },
  {
    id: '168',
    name: 'Gator Rocking Sprayer',
    category: 'Sprayers',
    subcategory: null,
    image: GATORROCKINGSPRAYER,
    youtubeLink: null,
    description: [
      'Spray lance: Brass 90 cm',
      'Pump barrel: Brass',
      '• Model No.: GR/25',
      '• Product Name: Rocking Sprayer',
      '• Brand: ASPEE',
      '• Pressure Vessel: Brass sheet',
      '• Pump Barrel: Brass',
      '• Spray Lance: Brass 90 cm',
      '• Delivery Hose: 5mt or as required, up to 30 mtr.',
      '• Dimension (L x W x H): 1397 x 216 x 254 mm',
      '• Weight (without discharge line): 9 kg (approx.)'
  ]
  
  },
  {
    id: '177',
    name: 'Ultima 555',
    category: 'Sprayers',
    subcategory: null,
    image: ULTIMA555,
    youtubeLink: null,
    description: [
      'SPECIFICATION:',
      '• INSIDE THE BOX DELIVERY HOSE: 15 MTR'
  ]
  
  },
  
  {
    id: '179',
    name: 'Ultima 3HP HTP Sprayer UL22',
    category: 'Sprayers',
    subcategory: null,
    image: Ultima3HPHTPSprayerUL22,
    youtubeLink: null,
    description: ['Ultima 3HP HTP Sprayer UL22 designed for high efficiency and effective spraying in agricultural fields.']
  },
  {
    id: '180',
    name: 'Eco Single Wheel',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: EcoSinglewheel,
    youtubeLink: null,
    description: [
      'Single Wheel Barrow Solid Tyre 120 Kg Load Capacity, often spelled as "wheelbarrow," is a simple yet versatile hand-propelled device used for carrying and transporting various materials, such as dirt, sand, gravel, construction debris, or gardening supplies.',
      'It typically consists of a single wheel, a frame, and a container or tray for holding the load. This tool is widely employed in construction sites, agriculture, gardening, landscaping, and various outdoor projects.',
      'The single wheel barrow operates on a basic lever and fulcrum principle. The user pushes down on the handles, which causes the wheelbarrow\'s single wheel to lift and the load to tip, making it easier to move heavy or bulky items over short distances.'
  ]
  
  },
  {
    id: '181',
    name: 'Eco Two Wheel WLB2TR',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: EcoTwoWheelWLB2TR,
    youtubeLink: null,
    description: [
      'The primary distinction is the presence of two wheels mounted on a common axle, providing increased stability and ease of use, especially when transporting heavy loads.',
      'This design offers improved balance and maneuverability, making it a popular choice for various gardening, landscaping, and construction tasks.',
      'Key Features of a Double Wheelbarrow:',
      '• Two Wheels: The most noticeable feature of a double wheelbarrow is the presence of two wheels instead of one. The wheels are typically positioned on each side of the wheelbarrow, providing increased stability and preventing tipping.',
      '• Common Axle: The two wheels are connected by a common axle, ensuring synchronized movement.',
      'Type of Product: Double Wheel Barrow',
      'Color: Green',
      'Application: Construction, FARMING',
      'No. Of Wheel: 2',
      'Country of Origin: India',
      'Product Type: Manual',
      'Designed For: Designed to be pushed and guided by a single person using two handles at the rear'
  ]
  
  },
  {
    id: '182',
    name: 'MTNWB3 Mathana 3 Wheel Barrow',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: MTNWB3Mathana3Wheelbarrow,
    youtubeLink: null,
    description: [
      'ADVANTAGES:',
      'Easy transport of materials without heavy lifting',
      'Easy to push without much effort',
      'Reduces manual labor',
      'High quality product with excellent after sale support',
      'USES:',
      'Transporting material in farm environment',
      'Useful for shop floors, industrial setups, shopping malls etc., to transport materials'
  ]
  
  
  },
  {
    id: '183',
    name: 'Push Cart Three Wheel With Breakup PCO3WWB',
    category: 'Wheel-Borrows',
    subcategory: null,
    image: PushcartThreeWheelWithBreakupPCO3WWB,
    youtubeLink: null,
    description: [
      'Overall Dimension: 1535L x 820W x 850H mm',
      'Weight: 35 Kg',
      'Paint: Powder Coated',
      'Tray Volume: 5 CFT'
  ]
  
  }
];

export default products;